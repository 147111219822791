import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpBackendService } from 'src/app/core/backend-services/http/http.backend-service';

@Injectable({
  providedIn: 'root',
})
export class ContactFormBackendService {
  private readonly BASE_ADDRESS = '/contact-form';

  /**
   * @description constructor of ContactFormBackendService
   * @param {HttpBackendService} http - http backend service
   */
  constructor(private http: HttpBackendService) {}

  /**
   * @description sends contact form message
   * @param {string} subject
   * @param {string} message
   * @returns {Promise<any>}
   */
  public send(subject: string, message: string): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/send`, {
        subject,
        message,
      })
    );
  }
}
