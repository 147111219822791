import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpBackendService } from 'src/app/core/backend-services/http/http.backend-service';

@Injectable({
  providedIn: 'root',
})
export class FriendshipBackendService {
  private readonly BASE_ADDRESS = '/friends';

  /**
   * @description constructor of FriendshipBackendService
   * @param {HttpBackendService} http - http backend service
   */
  constructor(private http: HttpBackendService) {}

  /**
   * @description new friend request
   * @param {number} userID
   * @returns {Promise<any>}
   */
  public newFriendshipRequest(userID: number): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/new-request`, {
        userID,
      })
    );
  }

  /**
   * @description withdraw friend request
   * @param {number} userID
   * @returns {Promise<any>}
   */
  public withdrawFriendshipRequest(userID: number): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/withdraw-request`, {
        userID,
      })
    );
  }

  /**
   * @description confirms friend request
   * @param {number} userID
   * @returns {Promise<any>}
   */
  public confirmFriendshipRequest(userID: number): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/confirm-request`, {
        userID,
      })
    );
  }

  /**
   * @description ignores friend request
   * @param {number} userID
   * @returns {Promise<any>}
   */
  public ignoreFriendshipRequest(userID: number): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/ignore-request`, {
        userID,
      })
    );
  }

  /**
   * @description unfriends friend
   * @param {number} userID
   * @returns {Promise<any>}
   */
  public unfriend(userID: number): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/unfriend`, {
        userID,
      })
    );
  }

  /**
   * @description gets incoming requests
   * @returns {Promise<any>}
   */
  public getIncomingRequests(): Promise<any[]> {
    return firstValueFrom(
      this.http.get<any[]>(`${this.BASE_ADDRESS}/incoming-requests`)
    );
  }

  /**
   * @description gets sent requests
   * @returns {Promise<any>}
   */
  public getSentRequests(): Promise<any[]> {
    return firstValueFrom(
      this.http.get<any[]>(`${this.BASE_ADDRESS}/sent-requests`)
    );
  }

  /**
   * @description gets friends
   * @returns {Promise<any>}
   */
  public getFriends(): Promise<any[]> {
    return firstValueFrom(this.http.get<any[]>(`${this.BASE_ADDRESS}`));
  }
}
