export type RoundRoles2Type = 'giver' | 'opponent';

export type RoundRoles3Type = 'shouter' | 'opponent1' | 'opponent2';

export type RoundRoles4Type = 'shouter' | 'opponent1' | 'partner' | 'opponent2';

export type RoundRolesType = RoundRoles2Type | RoundRoles3Type | RoundRoles4Type;

export namespace RoundRolesType {
  export function roundRoles2(): RoundRoles2Type[] {
    return ['giver', 'opponent'];
  }

  export function roundRoles3(): RoundRoles3Type[] {
    return ['shouter', 'opponent1', 'opponent2'];
  }

  export function roundRoles4(): RoundRoles4Type[] {
    return ['shouter', 'opponent1', 'partner', 'opponent2'];
  }
}
