/**
 * @file This is the socket io service module
 * @copyright Andreas Horvath 2016-2024
 * @version 0.2.0
 */
import { Injectable, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CsrfService } from '../csrf/csrf.service';

declare var io: any;

@Injectable({
  providedIn: 'root',
})
export class SocketIoService {
  private URL = environment.SERVER_URL;
  private API_PREFIX = '/';

  private socket: any;

  constructor(private ngZone: NgZone, private csrfService: CsrfService) {
    io.sails.url = this.URL;
    io.sails.reconnection = true;
    this.socket = io.sails.connect();
  }

  /**
   * checks if io.socket is ocnnected
   * @returns {Boolean}
   */
  get connected(): boolean {
    return this.socket.isConnected();
  }

  public get(url: string, data?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.socket.get(this.API_PREFIX + url, data, (resData, jwres) => {
        if (jwres.statusCode >= 200 && jwres.statusCode <= 299) {
          resolve(resData);
        } else {
          reject(resData);
        }
      });
    });
  }

  public post(url: string, data?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.socket.post(
        this.API_PREFIX + url,
        { ...data, _csrf: (window as any).csrfToken },
        (resData, jwres) => {
          if (jwres.statusCode >= 200 && jwres.statusCode <= 299) {
            resolve(resData);
          } else if (jwres.statusCode === 403 && resData === 'CSRF mismatch') {
            // if csrf mismatch, get new token and retry
            this.csrfService.refreshToken().then(() => {
              this.socket.post(
                this.API_PREFIX + url,
                { ...data, _csrf: (window as any).csrfToken },
                (resData, jwres) => {
                  if (jwres.statusCode >= 200 && jwres.statusCode <= 299) {
                    resolve(resData);
                  } else {
                    reject(resData);
                  }
                }
              );
            });
          } else {
            reject(resData);
          }
        }
      );
    });
  }

  public on(eventName: string, fun: Function): void {
    const zone = this.ngZone;
    this.socket.on(eventName, function (data) {
      zone.run(() => {
        fun(data);
      });
    });
  }

  public onMultiple(eventNames: string[], fun: Function): void {
    const zone = this.ngZone;
    for (const eventName of eventNames) {
      this.socket.on(eventName, function (data) {
        zone.run(() => {
          fun(data);
        });
      });
    }
  }
}
