import { Injectable } from '@angular/core';
import { SettingsInterface } from 'src/app/core/interfaces';
import { UserBackendService } from 'src/app/core/backend-services';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private initialized = new BehaviorSubject<boolean>(false);
  private _settings: SettingsInterface = SettingsInterface.builder().build();

  constructor(private userBackendService: UserBackendService) {}

  public async init(): Promise<void> {
    await this.update();
    this.initialized.next(true);
  }

  public async update(): Promise<void> {
    const settings = await this.userBackendService.getSettings();
    this._settings = SettingsInterface.builder()
      .androidDeviceID(settings.androidDeviceID)
      .cardDisplayType(settings.cardDisplayType)
      .hasActivePremiumSubscription(settings.hasActivePremiumSubscription)
      .musicEnabled(settings.musicEnabled)
      .sfxEnabled(settings.sfxEnabled)
      .notificationsEnabled(settings.notificationsEnabled)
      .vibrationEnabled(settings.vibrationEnabled)
      .voicesEnabled(settings.voicesEnabled)
      .build();
  }

  public async waitForInit(): Promise<void> {
    await firstValueFrom(
      this.initialized.asObservable().pipe(filter((el) => !!el))
    );
  }

  public get settings(): SettingsInterface {
    return this._settings;
  }
}
