/**
 * @file This is the connection service module
 * @copyright Andreas Horvath 2016-2024
 * @version 0.2.0
 */
import { Injectable } from '@angular/core';
import { AuthBackendService } from '../../backend-services';
import { MyPlayerService } from 'src/app/modules/game/services/my-player.service';
import { GameShellService } from 'src/app/modules/game/services/game-shell.service';
import { LobbyService } from 'src/app/modules/game/services/lobby.service';
import { InfoDialogService } from 'src/app/modules/game/services/info-dialog.service';
import { SocketIoService } from '../socket-io/socket-io.service';
import { GameStateService } from 'src/app/modules/game/services/game-state.service';
import { OnlineStatusEnum } from '../../enums';

declare var io: any;

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  private readonly SEND_PING_INTERVAL = 20000;

  // how long the client has to answer ping with pong before it is considered bad connection
  private readonly PING_TIMEOUT_BAD_CONNECTION = 1000; // if the client takes more than 1 second, it IS slow.

  private _lastPingTime = 0;
  private _lastPongTime = 0;
  private _lastHearbeatTime = 0; // how long the last heartbeat took
  private _heartbeatTimes: number[] = [];
  private _badConnection = false;

  constructor(
    private authBackendService: AuthBackendService,
    private infoDialogService: InfoDialogService,
    private myPlayer: MyPlayerService,
    private lobbyService: LobbyService,
    private gameShellService: GameShellService,
    private socketIO: SocketIoService,
    private state: GameStateService
  ) {
    console.log('Constructor of connection service called.');

    this.socketIO.on('connect', () => this._onConnect());
    this.socketIO.on('disconnect', (reason) => this._onDisconnect(reason));
    this.socketIO.on('reconnect', () => this._onReconnect());
    this.socketIO.on('reconnect_attempt', (attempt) =>
      this._onReconnectAttempt(attempt)
    );
    this.socketIO.on('reconnect_error', (err) => this._onReconnectError(err));
    this.socketIO.on('connectionLost', (data) =>
      this._playerDisconnected(data.data)
    );
    this.socketIO.on('reEntered', (data) => this._playerReconnected(data.data));
    this.socketIO.on('didAutoAction', (data) =>
      this._playerDidNotReact(data.data)
    );
    this.socketIO.on('userDidAction', (data) =>
      this._playerDidReact(data.data)
    );
    this.socketIO.on('reloadPage', () => location.reload());
    this.socketIO.on('logoff', () => this.authBackendService.logout());
    this.socketIO.on('logout', () => this.authBackendService.logout());
    this.socketIO.on('socketDisconnectNewWSID', () => {
      this._socketDisconnectNewWSID();
    });

    // io.socket._raw.io.engine._callbacks.$ping.push(() => this._pingHandler());
    // io.socket._raw.io.engine._callbacks.$pong.push(() => this._pongHandler());

    setInterval(() => {
      this._sendPing();
    }, this.SEND_PING_INTERVAL);
  }

  /**
   * checks if io.socket is ocnnected
   * @returns {Boolean}
   */
  public get connected() {
    return io.socket.isConnected();
  }

  /**
   * checks if connection is bad (when ping-pong takes very long)
   * @returns {Boolean}
   */
  public get hasBadConnection() {
    return this._badConnection;
  }

  /**
   * gets last heartbeat time
   * @returns {Number}
   */
  public get lastHearbeatTime() {
    return this._lastHearbeatTime;
  }

  private async _sendPing() {
    await this.socketIO.post('connection/ping');
  }

  /**
   * called when _pongHandler notices that i got a bad connection
   * @param {Number} lastHeartbeat
   */
  async _gotBadConnection(lastHeartbeat) {
    console.log(
      `It seems like your connection is not very good, your last Heartbeat took ${lastHeartbeat} ms.`
    );
  }

  /**
   * called when i lose connection
   * @param {Object} reason
   */
  private async _onDisconnect(reason) {
    console.log('WebSocket got disconnected from server. Reason: ' + reason);
  }

  /**
   * called when i reconnect
   */
  private async _onReconnect() {
    console.log('WebSocket reconnected to server.');

    try {
      await this.myPlayer.update();

      if (this.gameShellService.currentView === 'game') {
        await this.gameShellService.refreshGameAfterDisconnect();
      } else {
        await this.lobbyService.update();
      }
    } catch (e) {
      console.log('Error after reconnect to server:', e);
      console.log('Reloading page.');
      return location.reload();
    }

    this.infoDialogService.hideInfoDialog(
      'Deine Verbindung wurde getrennt',
      'Es wird gerade versucht, eine Verbindung herzustellen..'
    );

    if (this.gameShellService.currentView === 'game')
      await this.state.updatePlayerInfosFromBackend();
  }

  /**
   * called when i do a reconnect attempt
   * @param {Number} attempt
   */
  private async _onReconnectAttempt(attempt) {
    console.log(
      `WebSocket trying to reconnect to server... (Attempt #${attempt})`
    );
  }

  /**
   * called when i do a reconnect attempt and encountered an error
   * @param {Object} err
   */
  private async _onReconnectError(err): Promise<void> {
    if (this.socketIO.connected) return;

    console.log(`WebSocket could not reconnect to server.`, err);

    this.infoDialogService.showInfoDialog(
      'Deine Verbindung wurde getrennt',
      'Es wird gerade versucht, eine Verbindung herzustellen..',
      -1
    );
  }

  /**
   * invoked when server disconnected me for not doing anything
   */
  private async _serverDisconnectedMe(): Promise<void> {
    this.myPlayer.player.onlineStatus = OnlineStatusEnum.OFFLINE;

    console.log(
      'The server disconnected you. ' +
        'To reconnect, make a socket-POST call to /user/init (and do a reEnter)'
    );

    this.infoDialogService.showInfoDialog(
      'Deine Verbindung wurde getrennt',
      'Zum Wiederherstellen der Verbindung lade die Seite neu (F5).'
    );

    this._closeConnectionAndNeverReconnect();
  }

  /**s
   * invoked when some player gets disconnected
   * @param {Object} data
   */
  private async _playerDisconnected(data: any): Promise<void> {
    console.log('_playerDisconnected', data);

    if (this.myPlayer.player.userID === data.userID)
      return await this._serverDisconnectedMe();
    if (
      !this.myPlayer.player.table ||
      this.gameShellService.currentView !== 'game'
    )
      return;

    const player =
      data.userID === this.myPlayer.player.userID
        ? this.myPlayer.player
        : this.state.players[
            this.state.players.map((el) => el.userID).indexOf(data.userID)
          ];

    if (player.onlineStatus !== OnlineStatusEnum.OFFLINE) {
      player.onlineStatus = OnlineStatusEnum.OFFLINE;

      this.infoDialogService.showInfoDialog(
        player.username,
        'hat die Verbindung verloren.'
      );
    }
  }

  /**
   * invoked when some other player reconnects (reEntered)
   * @param {Object} data
   */
  private async _playerReconnected(data: any): Promise<void> {
    console.log('_playerReconnected', data);

    if (
      !this.myPlayer.player.table ||
      this.gameShellService.currentView !== 'game'
    )
      return;

    await this.state.updatePlayerInfosFromBackend();
  }

  /**
   * invoked when some other player did not react and the computer did an auto action
   * @param {Object} data
   */
  private async _playerDidNotReact(data: any): Promise<void> {
    console.log('_playerDidNotReact', data);

    if (
      !this.myPlayer.player.table ||
      this.gameShellService.currentView !== 'game'
    )
      return;

    const player =
      data.userID === this.myPlayer.player.userID
        ? this.myPlayer.player
        : this.state.players[
            this.state.players.map((el) => el.userID).indexOf(data.userID)
          ];

    if (player.onlineStatus === OnlineStatusEnum.ONLINE) {
      player.onlineStatus = OnlineStatusEnum.NOT_REACTING;
    }
  }

  /**
   * invoked when some other player did an action by themselves (opposite of did not react)
   * @param {Object} data
   */
  private async _playerDidReact(data: any): Promise<void> {
    console.log('_playerDidReact', data);

    if (
      !this.myPlayer.player.table ||
      this.gameShellService.currentView !== 'game'
    )
      return;

    const player =
      data.userID === this.myPlayer.player.userID
        ? this.myPlayer.player
        : this.state.players[
            this.state.players.map((el) => el.userID).indexOf(data.userID)
          ];

    if (player.onlineStatus === OnlineStatusEnum.NOT_REACTING) {
      player.onlineStatus = OnlineStatusEnum.ONLINE;
    }
  }

  /**
   * called on connect
   */
  private async _onConnect() {
    console.log('WebSocket connected.');
    try {
      await this.socketIO.post('user/init');
    } catch (e) {
      console.warn('Could not init user:', e);
    }
  }

  private _socketDisconnectNewWSID() {
    this.infoDialogService.showInfoDialog(
      `Verbindung geschlossen`,
      `Die Verbindung wurde geschlossen, weil das Spiel in einem anderen Browserfenster aktiv ist.`,
      -1
    );
    this._closeConnectionAndNeverReconnect();
  }

  /**
   * socket io ping handler
   */
  private _pingHandler() {
    this._lastPingTime = Date.now();
  }

  /**
   * socket io pong handler
   */
  private _pongHandler() {
    this._lastPongTime = Date.now();
    if (this._lastPingTime)
      this._lastHearbeatTime = this._lastPongTime - this._lastPingTime;

    if (this._lastHearbeatTime > this.PING_TIMEOUT_BAD_CONNECTION) {
      if (this._badConnection === false) {
        this._badConnection = true;
        this._gotBadConnection(this._lastHearbeatTime);
      }
    } else {
      this._badConnection = false;
    }

    this._heartbeatTimes.push(this._lastHearbeatTime);
  }

  private _closeConnectionAndNeverReconnect() {
    console.log('Permanently closed connection.');
    io.socket.disconnect();
    io.socket.reconnection = false;
  }
}
