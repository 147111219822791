import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpBackendService } from 'src/app/core/backend-services/http/http.backend-service';
import { AvatarType } from '../../types';

@Injectable({
  providedIn: 'root',
})
export class AvatarBackendService {
  private readonly BASE_ADDRESS = '/user/avatar';

  /**
   * @description constructor of AvatarBackendService
   * @param {HttpBackendService} http - http backend service
   */
  constructor(private http: HttpBackendService) {}

  /**
   * @description change avatar
   * @param {AvatarType} avatar
   * @returns {Promise<any>}
   */
  public change(avatar: AvatarType): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/update`, {
        avatar,
      })
    );
  }
}
