/**
 * @file This is the util service module
 * @copyright Andreas Horvath 2016-2024
 * @version 0.2.0
 */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {}

  isMobile(): boolean {
    if (window.matchMedia('only screen and (max-width: 760px)').matches)
      return true;

    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  isTouchDevice(): boolean {
    if (!!(window as any).isTouchDevice) return true;
    if (window.matchMedia('(pointer: coarse)').matches) {
      (window as any).isTouchDevice = true;
      return true;
    }
  }
}
