import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PlayerInterface, UserFlagsInterface } from 'src/app/core/interfaces';
import {
  AnnouncementType,
  CardColorType,
  CardType,
  CardValueType,
  RoundRolesType,
} from 'src/app/core/types';
import { wait, arrayRotate } from 'src/app/core/util';
import { sortCards } from 'src/app/core/util/card-helper/card-helper.util';
import { GameEventService } from './game-event.service';
import { PlayerUtilService } from './player-util.service';
import { MyPlayerService } from './my-player.service';

@Injectable({
  providedIn: 'root',
})
export class GameStateService {
  private originalPlayersSource: BehaviorSubject<PlayerInterface[]> =
    new BehaviorSubject([]);
  private myIdxSource: BehaviorSubject<number> = new BehaviorSubject(0);
  private rotationSource: BehaviorSubject<number> = new BehaviorSubject(0);
  private rolesSource: BehaviorSubject<RoundRolesType[]> = new BehaviorSubject(
    []
  );
  private playersSource: BehaviorSubject<PlayerInterface[]> =
    new BehaviorSubject([]);
  private waitingForSource: BehaviorSubject<any> = new BehaviorSubject({});
  private gameCountSource: BehaviorSubject<number[]> = new BehaviorSubject([
    0, 0,
  ]);
  private roundCountSource: BehaviorSubject<number[]> = new BehaviorSubject([
    0, 0,
  ]);
  private pendingRoundCountSource: BehaviorSubject<number[]> =
    new BehaviorSubject([0, 0]);
  private roundAnnouncementSource: BehaviorSubject<
    | {
        playerIdx: number;
        announcement: AnnouncementType;
      }
    | undefined
  > = new BehaviorSubject(undefined);
  private myCardsSource: BehaviorSubject<
    { allowed: boolean; focused: boolean; card: CardType }[]
  > = new BehaviorSubject([]); // down
  private myRawCardsSource: BehaviorSubject<CardType[]> = new BehaviorSubject(
    []
  );
  private otherPlayerNumCardsSource: BehaviorSubject<number[]> =
    new BehaviorSubject([0, 0, 0]); // left, up, right
  private tableCardsSource: BehaviorSubject<
    {
      position: number;
      card: CardType;
      subposition?: number;
      staplePosition: number | undefined;
      _staplePosition?: number | undefined;
    }[]
  > = new BehaviorSubject([]); // all tricks but not atout etc
  private numStapleCardsSource: BehaviorSubject<number> = new BehaviorSubject(
    0
  );
  private atoutCardSource: BehaviorSubject<CardType | undefined> =
    new BehaviorSubject(undefined);
  private zuadrahtSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private atoutSource: BehaviorSubject<CardColorType | undefined> =
    new BehaviorSubject(undefined);
  private pausedSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private show3TalonSource: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private allowCardClickSource: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private marriageSuitsOfPlayersSource: BehaviorSubject<CardColorType[][]> =
    new BehaviorSubject([[], [], [], []]); // sorted by index in game.players (origPl), not display idx
  private roundHashSource: BehaviorSubject<string> = new BehaviorSubject('');
  private newestCardAfterTrickEndSource: BehaviorSubject<CardType | undefined> =
    new BehaviorSubject(undefined);
  private gameWillEndSource: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  public originalPlayers$: Observable<PlayerInterface[]>;
  public myIdx$: Observable<number>;
  public rotation$: Observable<number>;
  public roles$: Observable<RoundRolesType[]>;
  public players$: Observable<PlayerInterface[]>;
  public waitingFor$: Observable<any>;
  public gameCount$: Observable<number[]>;
  public roundCount$: Observable<number[]>;
  public pendingRoundCount$: Observable<number[]>;
  public roundAnnouncement$: Observable<{
    playerIdx: number;
    announcement: AnnouncementType;
  }>;
  public myCards$: Observable<
    { allowed: boolean; focused: boolean; card: CardType }[]
  >;
  public myRawCards$: Observable<CardType[]>;
  public otherPlayerNumCards$: Observable<number[]>;
  public tableCards$: Observable<
    {
      position: number;
      card: CardType;
      staplePosition: number | undefined;
      _staplePosition?: number | undefined;
    }[]
  >;
  public numStapleCards$: Observable<number>;
  public atoutCard$: Observable<CardType | undefined>;
  public zuadraht$: Observable<boolean>;
  public atout$: Observable<CardColorType | undefined>;
  public paused$: Observable<boolean>;
  public show3Talon$: Observable<boolean>;
  public allowCardClick$: Observable<boolean>;
  public marriageSuitsOfPlayers$: Observable<CardColorType[][]>;
  public roundHash$: Observable<string>;
  public newestCardAfterTrickEnd$: Observable<string>;
  public gameWillEnd$: Observable<boolean>;

  public isRoundPlaying: boolean = false; // !!! is set to false 3s after roundEnd
  public isRoundRunning: boolean = false; // !!! is set to false immediately after roundEnd
  public isNPCGame: boolean = false;
  public isSpectatorGame: boolean = false;
  private _isNPCRoundActive: boolean = false;
  private _NPCRoundJustStarted: boolean = false;

  constructor(
    private gameEventService: GameEventService,
    private playerUtilService: PlayerUtilService,
    private myPlayerService: MyPlayerService
  ) {
    this.originalPlayers$ = this.originalPlayersSource.asObservable();
    this.myIdx$ = this.myIdxSource.asObservable();
    this.roles$ = this.rolesSource.asObservable();
    this.rotation$ = this.rotationSource.asObservable();
    this.players$ = this.playersSource.asObservable();
    this.waitingFor$ = this.waitingForSource.asObservable();
    this.gameCount$ = this.gameCountSource.asObservable();
    this.roundCount$ = this.roundCountSource.asObservable();
    this.pendingRoundCount$ = this.pendingRoundCountSource.asObservable();
    this.roundAnnouncement$ = this.roundAnnouncementSource.asObservable();
    this.roundCount$ = this.roundCountSource.asObservable();
    this.otherPlayerNumCards$ = this.otherPlayerNumCardsSource.asObservable();
    this.tableCards$ = this.tableCardsSource.asObservable();
    this.numStapleCards$ = this.numStapleCardsSource.asObservable();
    this.myCards$ = this.myCardsSource.asObservable();
    this.myRawCards$ = this.myRawCardsSource.asObservable();
    this.atoutCard$ = this.atoutCardSource.asObservable();
    this.zuadraht$ = this.zuadrahtSource.asObservable();
    this.atout$ = this.atoutSource.asObservable();
    this.paused$ = this.pausedSource.asObservable();
    this.show3Talon$ = this.show3TalonSource.asObservable();
    this.allowCardClick$ = this.allowCardClickSource.asObservable();
    this.marriageSuitsOfPlayers$ =
      this.marriageSuitsOfPlayersSource.asObservable();
    this.roundHash$ = this.roundHashSource.asObservable();
    this.newestCardAfterTrickEnd$ =
      this.newestCardAfterTrickEndSource.asObservable();
    this.gameWillEnd$ = this.gameWillEndSource.asObservable();

    this.gameEventService.gameEvent$.subscribe((event) =>
      this.handleAction(event)
    );
  }

  public get originalPlayers(): PlayerInterface[] {
    return this.originalPlayersSource.getValue();
  }

  public get myIdx(): number {
    return this.myIdxSource.getValue();
  }

  public get roles(): RoundRolesType[] {
    return this.rolesSource.getValue();
  }

  public get rotation(): number {
    return this.rotationSource.getValue();
  }

  public get players(): PlayerInterface[] {
    return this.playersSource.getValue();
  }

  public get waitingFor(): any {
    return this.waitingForSource.getValue();
  }

  public get gameCount(): number[] {
    return this.gameCountSource.getValue();
  }

  public get roundCount(): number[] {
    return this.roundCountSource.getValue();
  }

  public get pendingRoundCount(): number[] {
    return this.pendingRoundCountSource.getValue();
  }

  public get roundAnnouncement():
    | {
        playerIdx: number;
        announcement: AnnouncementType;
      }
    | undefined {
    return this.roundAnnouncementSource.getValue();
  }

  public get myCards(): {
    allowed: boolean;
    focused: boolean;
    card: CardType;
  }[] {
    return this.myCardsSource.getValue();
  }

  public get myRawCards(): CardType[] {
    return this.myRawCardsSource.getValue();
  }

  public get otherPlayerNumCards(): number[] {
    return this.otherPlayerNumCardsSource.getValue();
  }

  public get tableCards(): {
    position: number;
    card: CardType;
    staplePosition: number | undefined;
    _staplePosition?: number | undefined;
  }[] {
    return this.tableCardsSource.getValue();
  }

  public get numStapleCards(): number {
    return this.numStapleCardsSource.getValue();
  }

  public get atoutCard(): CardType | undefined {
    return this.atoutCardSource.getValue();
  }

  public get zuadraht(): boolean {
    return this.zuadrahtSource.getValue();
  }

  public get atout(): CardColorType | undefined {
    return this.atoutSource.getValue();
  }

  public get paused(): boolean {
    return this.pausedSource.getValue();
  }

  public get show3Talon(): boolean {
    return this.show3TalonSource.getValue();
  }

  public get allowCardClick(): boolean {
    return this.allowCardClickSource.getValue();
  }

  public get marriageSuitsOfPlayers(): CardColorType[][] {
    return this.marriageSuitsOfPlayersSource.getValue();
  }

  public get roundHash(): string {
    return this.roundHashSource.getValue();
  }

  public get newestCardAfterTrickEnd(): CardType | undefined {
    return this.newestCardAfterTrickEndSource.getValue();
  }

  public get gameWillEnd(): boolean {
    return this.gameWillEndSource.getValue();
  }

  public async setPlayers(
    players: PlayerInterface[],
    myIdx: number
  ): Promise<void> {
    this.myIdxSource.next(myIdx);
    this.originalPlayersSource.next(players);
    if (myIdx === -1) {
      this.rotationSource.next(0);
      this.playersSource.next(players);
    } else {
      this.rotationSource.next(
        this.originalPlayersSource.getValue().length -
          this.myIdxSource.getValue()
      );
      this.playersSource.next(
        arrayRotate(
          this.originalPlayersSource.getValue(),
          -this.rotationSource.getValue()
        )
      );
    }
  }

  public async updatePlayerInfosFromBackend() {
    if (this.playersSource.value) {
      await Promise.all(
        this.playersSource.value
          .filter((el) => !el.isNPC)
          .map((player) => this.playerUtilService.updateFromBackend(player))
      );
    }
  }

  public reset(resetGameType: boolean = false) {
    this.marriageSuitsOfPlayersSource.next([[], [], [], []]);
    this.rolesSource.next([]);
    this.updateMyRawCards([]); // also updates myCards
    this.otherPlayerNumCardsSource.next([0, 0, 0]);
    this.tableCardsSource.next([]);
    this.roundCountSource.next([0, 0]);
    this.pendingRoundCountSource.next([0, 0]);
    this.atoutCardSource.next(undefined);
    this.atoutSource.next(undefined);
    this.zuadrahtSource.next(false);
    this.roundAnnouncementSource.next(undefined);
    this.pausedSource.next(undefined);
    this.isRoundPlaying = false;
    this.isRoundRunning = false;
    this.roundHashSource.next('');
    this.newestCardAfterTrickEndSource.next(undefined);

    if (resetGameType) {
      this.isNPCGame = false;
      this.isSpectatorGame = false;
      this.gameWillEndSource.next(false);
    }
  }

  public getMyRoundCount(): number {
    if (
      this.playersSource.value.length === 2 ||
      this.playersSource.value.length === 4
    ) {
      const cnt = this.roundCountSource.value[this.myIdxSource.value % 2];
      const pendingCnt =
        this.pendingRoundCountSource.value[this.myIdxSource.value % 2];
      return cnt > 0 ? cnt + pendingCnt : cnt;
    } else if (this.playersSource.value.length === 3) {
      if (!this.roundAnnouncementSource.value) return 0;
      let cnt: number = 0;
      let pendingCnt: number = 0;
      if (
        this.roundAnnouncementSource.value!.playerIdx === this.myIdxSource.value
      ) {
        cnt = this.roundCountSource.value[0];
        pendingCnt = this.pendingRoundCountSource.value[0];
      } else {
        cnt = this.roundCountSource.value[1];
        pendingCnt = this.pendingRoundCountSource.value[1];
      }
      return cnt > 0 ? cnt + pendingCnt : cnt;
    }
  }

  public areWeWaitingFor(displayIdx: number): boolean {
    if (
      !this.waitingForSource.getValue() ||
      this.waitingForSource.getValue().playerIdx === -1
    )
      return false;
    if (
      this.getDisplayPlayerIdx(this.waitingForSource.getValue().playerIdx) ===
      displayIdx
    )
      return true;
    if (
      this.waitingForSource.getValue().additionalPlayerIdx &&
      this.getDisplayPlayerIdx(
        this.waitingForSource.getValue().additionalPlayerIdx
      ) === displayIdx
    )
      return true;
    return false;
  }

  public getPlayerFromDisplayIdx(
    displayIdx: number
  ): PlayerInterface | undefined {
    if (this.playersSource.getValue().length === 2) {
      if (displayIdx === 0) return this.playersSource.getValue()[0];
      else if (displayIdx === 2) return this.playersSource.getValue()[1];
    } else if (this.playersSource.getValue().length === 4) {
      return this.playersSource.getValue()[displayIdx];
    } else if (this.playersSource.getValue().length === 3) {
      if (displayIdx === 0) return this.playersSource.getValue()[0];
      else if (displayIdx === 1) return this.playersSource.getValue()[1];
      else if (displayIdx === 2) return this.playersSource.getValue()[2];
    }
    return undefined;
  }

  /**
   * gets display player idx (0,1,2,3) where 0 is myself, 1 is left, and so on from playerIdx
   * @param {number} idx
   * @returns {number}
   */
  public getDisplayPlayerIdx(idx: number): number {
    if (this.playersSource.getValue().length === 2) {
      if (
        (idx + this.rotationSource.getValue()) %
          this.playersSource.getValue().length ===
        0
      ) {
        return 0;
      } else return 2;
    } else if (this.playersSource.getValue().length === 4) {
      return (
        (idx + this.rotationSource.getValue()) %
        this.playersSource.getValue().length
      );
    } else if (this.playersSource.getValue().length === 3) {
      return (
        (idx + this.rotationSource.getValue()) %
        this.playersSource.getValue().length
      );
    }
  }

  private async handleAction(event: { name: string; data: any }) {
    if (event.name === 'gameCountUpdate') {
      this.gameCountSource.next(event.data.count);
    } else if (event.name === 'roundEnd') {
      this._isNPCRoundActive = false;
      this.isRoundRunning = false;
      await wait(2000);
      this.reset();
    } else if (event.name === 'zuadraht') {
      this.zuadrahtSource.next(true);
    } else if (event.name === 'rolesUpdate') {
      this.rolesSource.next(event.data.roles);
    } else if (event.name === 'atoutUpdate') {
      this.atoutSource.next(event.data.atout);
    } else if (
      event.name === 'talonUpdate' &&
      this.playersSource.getValue().length === 2
    ) {
      this.numStapleCardsSource.next(event.data.numCards);
      this.atoutCardSource.next(event.data.atoutCard);
    } else if (event.name === 'roundCountUpdate') {
      this.roundCountSource.next(event.data.count);
    } else if (event.name === 'pendingCountUpdate') {
      this.pendingRoundCountSource.next(event.data.count);
    } else if (event.name === 'finalRoundAnnouncementIs') {
      this.roundAnnouncementSource.next(event.data);
    } else if (event.name === 'newRound') {
      this.isRoundRunning = true;
      this.isRoundPlaying = true;
      this.gameWillEndSource.next(false);
      if (this.isNPCGame) this._NPCRoundJustStarted = true;
      if (this.isNPCGame) this._isNPCRoundActive = true;
      this.roundHashSource.next(
        `${event.data.gameUuid}-${event.data.roundIdx}`
      );
    } else if (event.name === 'cardPlayed') {
      let tableCards = this.tableCardsSource.getValue();
      tableCards.push({
        position: this.getDisplayPlayerIdx(event.data.playerIdx),
        card: event.data.card,
        staplePosition: undefined,
      });
      this.tableCardsSource.next(tableCards);
    } else if (event.name === 'cardGiven') {
      let tableCards = this.tableCardsSource.getValue();
      tableCards.push({
        position: this.getDisplayPlayerIdx(event.data.playerIdx),
        card: event.data.card,
        staplePosition: undefined,
      });
      this.tableCardsSource.next(tableCards);
    } else if (event.name === 'cardUpdate') {
      this.updateMyRawCards(event.data.cards);
      this.allowCardClickSource.next(false);
    } else if (event.name === 'trickEnd') {
      const staplePosition = this.getDisplayPlayerIdx(event.data.winnerIdx);
      let tableCards = this.tableCardsSource.getValue();
      for (let card of event.data.trick.map((el) => el.card)) {
        tableCards[
          tableCards.map((el) => el.card).indexOf(card)
        ]._staplePosition = staplePosition;
      }
      this.tableCardsSource.next(tableCards);
      await wait(2000);
      tableCards = this.tableCardsSource.getValue();
      for (let card of event.data.trick.map((el) => el.card)) {
        tableCards[
          tableCards.map((el) => el.card).indexOf(card)
        ].staplePosition = staplePosition;
      }
      this.tableCardsSource.next(tableCards);
    } else if (event.name === 'othersCardUpdate') {
      if (this.getDisplayPlayerIdx(event.data.playerIdx) > 0) {
        let otherPlayerNumCards = this.otherPlayerNumCardsSource.getValue();
        otherPlayerNumCards[
          this.getDisplayPlayerIdx(event.data.playerIdx) - 1
        ] = event.data.numCards;
        this.otherPlayerNumCardsSource.next(otherPlayerNumCards);
      }
    } else if (event.name === 'waitingFor') {
      this.show3TalonSource.next(true);
      this.allowCardClickSource.next(false);
      this.waitingForSource.next(event.data);
      if (event.data.playerIdx === this.myIdxSource.getValue()) {
        if (
          event.data.action === 'playACard' ||
          event.data.action === 'giveCard'
        ) {
          let myCards = this.myCardsSource.getValue();
          myCards.forEach((el, idx) => {
            el.focused = true;
            el.allowed = event.data.validCards
              ? event.data.validCards.includes(
                  this.myRawCardsSource.getValue().indexOf(el.card)
                )
              : true;
          });
          this.updateMyCards(myCards);
          this.allowCardClickSource.next(true);
        }
        if (event.data.action === 'lookTalon') {
          this.show3TalonSource.next(false);
          let myCards = this.myCardsSource.getValue();
          myCards.push(
            ...event.data.talon.map((el) => ({
              card: el,
              focused: false,
              allowed: false,
            }))
          );
          myCards.forEach((el, idx) => {
            el.focused = false;
            el.allowed = true;
          });
          this.updateMyCards(myCards);
          this.allowCardClickSource.next(true);
        }
      } else {
        let myCards = this.myCardsSource.getValue();
        myCards.forEach((el, idx) => {
          el.focused = false;
          el.allowed = true;
        });
        this.updateMyCards(myCards);
      }
    } else if (event.name === 'paused') {
      this.pausedSource.next(true);
    } else if (event.name === 'pauseEnded') {
      this.pausedSource.next(false);
    } else if (event.name === 'gameEnd') {
      await wait(1000); // wait for backend to update
      await Promise.all([
        ...this.players.map((player) =>
          this.playerUtilService.updateFromBackend(player)
        ),
        this.playerUtilService.updateFromBackend(this.myPlayerService.player),
      ]);
    } else if (event.name === 'announceMarriage') {
      const suit = event.data.card.slice(0, 1) as CardColorType;
      const marriageSuitsOfPlayers = this.marriageSuitsOfPlayers;
      marriageSuitsOfPlayers[event.data.playerIdx].push(suit);
      this.marriageSuitsOfPlayersSource.next(marriageSuitsOfPlayers);
    } else if (event.name === 'gameWillEnd') {
      this.gameWillEndSource.next(true);
    }
  }

  public get isNPCRoundActive(): boolean {
    return this._isNPCRoundActive;
  }

  public get NPCRoundJustStarted(): boolean {
    return this._NPCRoundJustStarted;
  }

  public get isPaused(): boolean {
    return this.pausedSource.value;
  }

  public madeNPCBackendCall() {
    this._NPCRoundJustStarted = false;
  }

  public async updateMyRawCards(
    myCards: CardType[],
    updateMyCards: boolean = true
  ) {
    const oldRawCards = this.myRawCardsSource.getValue().slice();

    this.myRawCardsSource.next(myCards);

    if (updateMyCards) {
      const cardsArray = sortCards(
        myCards,
        this.atoutSource.getValue(),
        this.roundAnnouncementSource.getValue()
          ? this.roundAnnouncementSource.getValue().announcement
          : undefined
      );
      this.updateMyCards(
        cardsArray.map((el) => ({
          card: el,
          allowed: true,
          focused: false,
        }))
      );
    }

    if (this.tableCards.length > 0) {
      const newCard = myCards.find((el) => !oldRawCards.includes(el));
      if (newCard) {
        this.newestCardAfterTrickEndSource.next(newCard);
        await wait(1000);
        if (this.newestCardAfterTrickEndSource.getValue() === newCard) {
          this.newestCardAfterTrickEndSource.next(undefined);
        }
      }
    }
  }

  public updateMyCards(
    myCards: { card: CardType; allowed: boolean; focused: boolean }[]
  ) {
    this.myCardsSource.next(myCards);
  }

  public get showChangeAtoutButton(): boolean {
    return (
      this.players.length === 2 &&
      this.waitingFor.playerIdx === this.myIdx &&
      this.numStapleCards > 0 &&
      this.waitingFor.action === 'playACard' &&
      this.zuadraht === false &&
      this.atoutCard &&
      (this.atoutCard.slice(1, 2) as CardValueType) !== 'B' &&
      this.myCards
        .map((el) => el.card)
        .includes((this.atoutCard.slice(0, 1) + 'B') as CardType)
    );
  }

  public get showZuadrahnButton(): boolean {
    return (
      this.players.length === 2 &&
      this.waitingFor.playerIdx === this.myIdx &&
      this.numStapleCards > 0 &&
      this.waitingFor.action === 'playACard' &&
      this.zuadraht === false
    );
  }

  public get showTalon3Button(): boolean {
    return (
      this.players.length === 3 &&
      this.waitingFor.playerIdx === this.myIdx &&
      this.waitingFor.action === 'lookTalon'
    );
  }

  public get talon3ButtonDisabled(): boolean {
    return this.myCards.filter((el) => el.focused).length !== 2;
  }
}
