import { IBuilder, Builder } from 'builder-pattern';
import { CardDisplayType } from '../../types';
import { DEFAULT_CARD_DISPLAY_TYPE } from 'src/app/modules/game/services/card-display-type.service';

export interface SettingsInterface {
  androidDeviceID?: string;
  hasActivePremiumSubscription: boolean;
  cardDisplayType: CardDisplayType;
  sfxEnabled: boolean;
  voicesEnabled: boolean;
  musicEnabled: boolean;
  notificationsEnabled: boolean;
  vibrationEnabled: boolean;
}

export namespace SettingsInterface {
  export function builder(
    template?: Partial<SettingsInterface>
  ): IBuilder<SettingsInterface> {
    return Builder<SettingsInterface>(template)
      .androidDeviceID(template?.androidDeviceID)
      .hasActivePremiumSubscription(
        template?.hasActivePremiumSubscription ?? false
      )
      .cardDisplayType(template?.cardDisplayType ?? DEFAULT_CARD_DISPLAY_TYPE)
      .sfxEnabled(template?.sfxEnabled ?? true)
      .voicesEnabled(template?.voicesEnabled ?? true)
      .musicEnabled(template?.musicEnabled ?? true)
      .notificationsEnabled(template?.notificationsEnabled ?? true)
      .vibrationEnabled(template?.vibrationEnabled ?? true);
  }
}
