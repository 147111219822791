import { Builder, IBuilder } from 'builder-pattern';

export interface ContraUpdateInterface {
  playerIdx: number;
  re: boolean;
}

export namespace ContraUpdateInterface {
  export function builder(
    template?: Partial<ContraUpdateInterface>
  ): IBuilder<ContraUpdateInterface> {
    return Builder<ContraUpdateInterface>(template);
  }
}
