import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpBackendService } from 'src/app/core/backend-services/http/http.backend-service';
import { UserInfoInterface } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserBackendService {
  private readonly BASE_ADDRESS = '/user';

  /**
   * @description constructor of UserBackendService
   * @param {HttpBackendService} http - http backend service
   */
  constructor(private http: HttpBackendService) {}

  /**
   * @description gets online player list
   * @returns {Promise<any[]>}
   */
  public getCurrentlyOnlinePlayers(): Promise<UserInfoInterface[]> {
    return firstValueFrom(
      this.http.get<UserInfoInterface[]>(
        `${this.BASE_ADDRESS}/currently-online`
      )
    );
  }

  /**
   * @description gets user online info
   * @returns {Promise<{isOnline:boolean,isInactive:boolean}>}
   */
  public getOnlineInfo(
    userID: number
  ): Promise<{ isOnline: boolean; isInactive: boolean }> {
    return firstValueFrom(
      this.http.get<{ isOnline: boolean; isInactive: boolean }>(
        `${this.BASE_ADDRESS}/${userID}/online-info`
      )
    );
  }

  /**
   * @description gets user info
   * @returns {Promise<any>}
   */
  public getPlayerInfo(userID: number | undefined = undefined): Promise<any> {
    return firstValueFrom(
      this.http.get<any>(
        userID ? `${this.BASE_ADDRESS}/${userID}` : `${this.BASE_ADDRESS}`
      )
    );
  }

  /**
   * @description gets my user settings and details
   * @returns {Promise<any>}
   */
  public getSettings(): Promise<any> {
    return firstValueFrom(this.http.get<any>(`${this.BASE_ADDRESS}/settings`));
  }

  /**
   * @description registers user
   * @param {string} usernamex
   * @param {string} password
   * @param {string} email
   * @returns {Promise<any>}
   */
  public register(
    username: string,
    password: string,
    email: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/signup`, {
        username,
        password,
        email,
      })
    );
  }

  /**
   * @description user pw forgotten
   * @param {string} email
   * @returns {Promise<any>}
   */
  public passwordForgotten(email: string): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/password-forgotten`, {
        email,
      })
    );
  }

  /**
   * @description user change pw
   * @param {string} oldPassword
   * @param {string} newPassword
   * @returns {Promise<any>}
   */
  public changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/change-password`, {
        oldPassword,
        newPassword,
      })
    );
  }

  /**
   * @description user change username
   * @param {string} newUsername
   * @returns {Promise<any>}
   */
  public changeUsername(newUsername: string): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/change-username`, {
        username: newUsername,
      })
    );
  }

  /**
   * @description user pw reset
   * @param {string} token
   * @param {string} password
   * @returns {Promise<any>}
   */
  public resetPassword(token: string, password: string): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/password-reset`, {
        token,
        newPassword: password,
      })
    );
  }

  /**
   * @description user email confirm
   * @param {string} token
   * @returns {Promise<any>}
   */
  public confirmEmail(token: string): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/confirm-email`, {
        token,
      })
    );
  }
}
