import {
  AnnouncementType,
  CardType,
  RoundWaitingForActionType,
} from '../../types';
import { IBuilder, Builder } from 'builder-pattern';

export interface RoundWaitingForInterface {
  playerIdx: number;
  additionalPlayerIdx?: number; // for kontra and reKontra to have these in parallel
  action: RoundWaitingForActionType | 'nothing';
  validAnnouncements?: AnnouncementType[];
  validCards?: number[];
  talon?: CardType[];
  timestamp?: number;
}

export namespace RoundWaitingForInterface {
  export function builder(
    template?: Partial<RoundWaitingForInterface>
  ): IBuilder<RoundWaitingForInterface> {
    return Builder<RoundWaitingForInterface>(template).timestamp(
      template?.timestamp ?? Date.now()
    );
  }
}
