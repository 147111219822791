import { IBuilder, Builder } from 'builder-pattern';
import { SocketMessageType } from '../../types';

export interface GameMessageInterface {
  receiver: 0 | 1 | 2 | 3 | 'ALL';
  data: any;
  type: SocketMessageType;
  processed: boolean;
  timestamp: number;
}

export namespace GameMessageInterface {
  export function builder(
    template?: Partial<GameMessageInterface>
  ): IBuilder<GameMessageInterface> {
    return Builder<GameMessageInterface>(template)
      .processed(template?.processed ?? false)
      .timestamp(template?.timestamp ?? Date.now());
  }

  export function create(
    type: SocketMessageType,
    data: any,
    receiver: 0 | 1 | 2 | 3 | 'ALL' = 'ALL'
  ) {
    return Builder<GameMessageInterface>()
      .type(type)
      .data(data)
      .receiver(receiver)
      .processed(false)
      .timestamp(Date.now())
      .build();
  }
}
