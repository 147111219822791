import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpBackendService } from 'src/app/core/backend-services/http/http.backend-service';

@Injectable({
  providedIn: 'root',
})
export class CsrfBackendService {
  private readonly BASE_ADDRESS = '/csrf-token';

  /**
   * @description constructor of CsrfBackendService
   * @param {HttpBackendService} http - http backend service
   */
  constructor(private http: HttpBackendService) {}

  /**
   * @description gets csrf token
   * @returns {Promise<string>}
   */
  public async getToken(): Promise<string> {
    const v = await firstValueFrom(this.http.get<any>(`${this.BASE_ADDRESS}`));
    return v._csrf;
  }
}
