import {
  AtoutCardChangedInterface,
  ContraUpdateInterface,
  RoundAnnouncementInterface,
  RoundWaitingForInterface,
  TrickInterface,
  ZuadrahtInterface,
} from '..';
import { CardColorType, CardType, RoundRolesType } from '../../types';
import { IBuilder, Builder } from 'builder-pattern';

export interface RoundInterface {
  cards: CardType[];
  playercards: CardType[][];
  roles: RoundRolesType[];
  atout: CardColorType | undefined;
  points: number[];
  pendingPoints: number[];
  announcement: RoundAnnouncementInterface | undefined;
  waitingFor: RoundWaitingForInterface | undefined;
  announcementHistory: RoundAnnouncementInterface[];
  contraFactor: number;
  contraUpdates: ContraUpdateInterface[];
  talon: CardType[];
  seenTalon: boolean;
  zuadraht: ZuadrahtInterface | undefined;
  atoutCard: CardType | undefined;
  atoutCardChanged: AtoutCardChangedInterface | undefined;
  tricks: TrickInterface[];
}

export namespace RoundInterface {
  export function builder(
    template?: Partial<RoundInterface>
  ): IBuilder<RoundInterface> {
    return Builder<RoundInterface>(template)
      .cards(template?.cards ?? [])
      .playercards(template?.playercards ?? [])
      .roles(template?.roles ?? [])
      .points(template?.points ?? [])
      .pendingPoints(template?.pendingPoints ?? [])
      .announcementHistory(template?.announcementHistory ?? [])
      .contraUpdates(template?.contraUpdates ?? [])
      .talon(template?.talon ?? [])
      .tricks(template?.tricks ?? []);
  }
}
