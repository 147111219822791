import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpBackendService } from 'src/app/core/backend-services/http/http.backend-service';

@Injectable({
  providedIn: 'root',
})
export class RoundArchiveBackendService {
  private readonly BASE_ADDRESS = '/game/archived-round';

  /**
   * @description constructor of RoundArchiveBackendService
   * @param {HttpBackendService} http - http backend service
   */
  constructor(private http: HttpBackendService) {}

  /**
   * @description gets an archived round
   * @param {string} uuid
   * @returns {Promise<any>}
   */
  public get(uuid: string): Promise<any> {
    return firstValueFrom(this.http.get<any>(`${this.BASE_ADDRESS}/${uuid}`));
  }
}
