import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpBackendService } from 'src/app/core/backend-services/http/http.backend-service';

@Injectable({
  providedIn: 'root',
})
export class EnmityBackendService {
  private readonly BASE_ADDRESS = '/enemies';

  /**
   * @description constructor of EnmityBackendService
   * @param {HttpBackendService} http - http backend service
   */
  constructor(private http: HttpBackendService) {}

  /**
   * @description new enemy
   * @param {number} userID
   * @returns {Promise<any>}
   */
  public newEnemy(userID: number): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/new`, {
        userID,
      })
    );
  }

  /**
   * @description forgives
   * @param {number} userID
   * @returns {Promise<any>}
   */
  public forgive(userID: number): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/forgive`, {
        userID,
      })
    );
  }

  /**
   * @description gets enemies
   * @returns {Promise<any>}
   */
  public getEnemies(): Promise<any[]> {
    return firstValueFrom(this.http.get<any[]>(`${this.BASE_ADDRESS}/my-list`));
  }
}
