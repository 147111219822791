/**
 * @file This is the csrf service module
 * @copyright Andreas Horvath 2016-2024
 * @version 0.2.0
 */
import { Injectable } from '@angular/core';
import { CsrfBackendService } from '../../backend-services';

@Injectable({
  providedIn: 'root',
})
export class CsrfService {
  constructor(private csrfBackendService: CsrfBackendService) {
    void this.refreshToken();
  }

  async refreshToken() {
    try {
      const token = await this.csrfBackendService.getToken();
      (window as any).csrfToken = token;
    } catch (e) {
      console.warn('csrf token error', e);
    }
  }
}
