import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InfoDialogService {
  private _infoDialogHeadline: string = '';
  private _infoDialogShown: boolean = false;
  private infoDialogTimeoutHandle: any;
  private _infoDialogTextSource: BehaviorSubject<string> = new BehaviorSubject(
    ''
  );
  public infoDialogText$: Observable<string> =
    this._infoDialogTextSource.asObservable();

  constructor() {}

  public get infoDialogHeadline(): string {
    return this._infoDialogHeadline;
  }
  public get infoDialogText(): string {
    return this._infoDialogTextSource.getValue();
  }
  public get infoDialogShown(): boolean {
    return this._infoDialogShown;
  }

  public updateText(text: string): void {
    this._infoDialogTextSource.next(text);
  }

  public showInfoDialog(
    headline: string,
    text: string,
    showTime: number = 2000
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.infoDialogTimeoutHandle)
        clearTimeout(this.infoDialogTimeoutHandle);
      this._infoDialogHeadline = headline;
      this.updateText(text);
      this._infoDialogShown = true;
      if (showTime === -1) {
        this.infoDialogTimeoutHandle = undefined;
        resolve();
      } else {
        this.infoDialogTimeoutHandle = setTimeout(() => {
          this._infoDialogShown = false;
          this.infoDialogTimeoutHandle = undefined;
          resolve();
        }, showTime);
      }
    });
  }

  public hideInfoDialog(headline?: string, text?: string): void {
    if (
      (!headline && !text) ||
      (this._infoDialogHeadline === headline &&
        this._infoDialogTextSource.value === text)
    ) {
      this.infoDialogTimeoutHandle = undefined;
      this._infoDialogShown = false;
    }
  }
}
