import { Injectable } from '@angular/core';
import { UserBackendService } from 'src/app/core/backend-services';
import { OnlineStatusEnum } from 'src/app/core/enums/online-status/online-status.enum';
import { PlayerInterface } from 'src/app/core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PlayerUtilService {
  constructor(private userBackendService: UserBackendService) {}

  public async updateFromBackend(player: PlayerInterface) {
    await Promise.all([
      this.updateOnlineStatus(player),
      this.updatePointsAndGlickoRating(player),
    ]);
  }

  public async updateOnlineStatus(player: PlayerInterface) {
    if (!player.userID) return;
    try {
      const info = await this.userBackendService.getOnlineInfo(player.userID);

      if (info.isOnline) {
        if (info.isInactive) {
          player.onlineStatus = OnlineStatusEnum.NOT_REACTING;
        } else {
          player.onlineStatus = OnlineStatusEnum.ONLINE;
        }
      } else {
        player.onlineStatus = OnlineStatusEnum.OFFLINE;
      }
    } catch (e) {
      console.warn(
        `Did not update player online status for userID ${player.userID}: `,
        e
      );
    }
  }

  public async updatePointsAndGlickoRating(player: PlayerInterface) {
    if (!player.userID) return;
    try {
      const info = await this.userBackendService.getPlayerInfo(player.userID);
      player.points = info.points;
      player.glicko2Rating = info.glicko2Rating;
    } catch (e) {
      console.warn(
        `Did not update player points for userID ${player.userID}: `,
        e
      );
    }
  }
}
