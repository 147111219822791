import { Component } from '@angular/core';
import { CsrfService } from './core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  constructor(private csrfService: CsrfService) {}

  title = 'schnapsen.online-frontend';
}
