import { Injectable } from '@angular/core';
import { MyPlayerInterface, UserInfoInterface } from 'src/app/core/interfaces';
import { SocketIoService } from 'src/app/core/services';
import { PlayerUtilService } from './player-util.service';
import { UserBackendService } from 'src/app/core/backend-services';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MyPlayerService {
  private initialized = new BehaviorSubject<boolean>(false);
  private _player: MyPlayerInterface;

  constructor(
    private socketIO: SocketIoService,
    private playerUtilService: PlayerUtilService,
    private userBackendService: UserBackendService
  ) {}

  public async update(): Promise<void> {
    const user: UserInfoInterface =
      await this.userBackendService.getPlayerInfo();
    this._player = MyPlayerInterface.builder()
      .userID(user.userID)
      .username(user.username)
      .avatar(user.avatar)
      .glicko2Rating(user.glicko2Rating)
      .points(user.points)
      .profilePicture(user.profilePicture)
      .smallProfilePicture(user.smallProfilePicture)
      .tableID(user.tableID)
      .table(user.table)
      .watchingTableID(user.watchingTableID)
      .build();
    await this.playerUtilService.updateOnlineStatus(this.player);
    if (this.player.table && this.player.table.members) {
      await Promise.all(
        this.player.table.members.map((player) =>
          this.playerUtilService.updateFromBackend(player)
        )
      );
    }
    this.initialized.next(true);
  }

  public async waitForInit(): Promise<void> {
    await firstValueFrom(
      this.initialized.asObservable().pipe(filter((el) => !!el))
    );
  }

  public get player(): MyPlayerInterface {
    return this._player;
  }

  public async setMyTable(table: any) {
    this._player.tableID = table ? table.id || 0 : 0;
    this._player.table = table;
    if (table && table.members) {
      await Promise.all(
        table.members.map((player) =>
          this.playerUtilService.updateFromBackend(player)
        )
      );
    }
  }
}
