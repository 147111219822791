import { IBuilder, Builder } from 'builder-pattern';
import * as uuid from 'uuid';
import { OnlineStatusEnum } from 'src/app/core/enums';

function getDefaultProfilePicture(): string {
  return `https://avatars.dicebear.com/api/adventurer/${uuid.v4()}.svg`;
}

export interface PlayerInterface {
  userID?: number;
  username?: string;
  glicko2Rating?: number;
  points?: number;
  profilePicture?: string;
  smallProfilePicture?: string;
  isFriend?: boolean;
  isEnemy?: boolean;
  isAdministrator?: boolean;
  avatar?: string;
  onlineStatus?: OnlineStatusEnum; // FE-only
  isNPC?: boolean; //set ONLY in FE for training games
}

export namespace PlayerInterface {
  export function builder(
    template?: Partial<PlayerInterface>
  ): IBuilder<PlayerInterface> {
    return Builder<PlayerInterface>(template)
      .profilePicture(template?.profilePicture ?? getDefaultProfilePicture())
      .isFriend(template?.isFriend ?? false)
      .isEnemy(template?.isEnemy ?? false)
      .isAdministrator(template?.isAdministrator ?? false);
  }
}

export interface MyPlayerInterface extends PlayerInterface {
  tableID: number;
  watchingTableID: number;
  table: any;
}

export namespace MyPlayerInterface {
  export function builder(
    template?: Partial<MyPlayerInterface>
  ): IBuilder<MyPlayerInterface> {
    return Builder<MyPlayerInterface>(template);
  }
}
