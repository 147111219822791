import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpBackendService } from 'src/app/core/backend-services/http/http.backend-service';

@Injectable({
  providedIn: 'root',
})
export class AuthBackendService {
  private readonly BASE_ADDRESS = '/user/auth';

  /**
   * @description constructor of AuthBackendService
   * @param {HttpBackendService} http - http backend service
   */
  constructor(private http: HttpBackendService) {}

  /**
   * @description logs in user
   * @param {string} username
   * @param {string} password
   * @returns {Promise<any>}
   */
  public login(username: string, password: string): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(`${this.BASE_ADDRESS}/login`, { username, password })
    );
  }

  /**
   * @description logs out user
   * @returns {Promise<any>}
   */
  public logout(): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(
        `${this.BASE_ADDRESS}/logout`,
        {},
        { responseType: 'text' as 'json' }
      )
    );
  }

  /**
   * @description checks logged in
   * @returns {Promise<boolean>}
   */
  public async checkLoggedIn(): Promise<boolean> {
    try {
      const res = await firstValueFrom(
        this.http.get<any>(`${this.BASE_ADDRESS}/is-logged-in`)
      );
      return res.isLoggedIn;
    } catch (e) {
      return false;
    }
  }
}
