import { GameMessageInterface } from '../game-message/game-message.interface';
import { PlayerInterface } from '../player/player.interface';
import { RoundInterface } from '../round/round.interface';
import { Builder, IBuilder } from 'builder-pattern';
import { uuid4 } from 'uuid4';

export interface GameInterface {
  uuid: string;
  rounds: RoundInterface[];
  points: number[];
  pointsHistory: number[][];
  ended: boolean;
  isNPCGame: boolean;
  numPlayers: 2 | 3 | 4;
  messages: GameMessageInterface[];
  startedAt: Date;
  players: PlayerInterface[];
}

export namespace GameInterface {
  export function builder(
    template?: Partial<GameInterface>
  ): IBuilder<GameInterface> {
    return Builder<GameInterface>(template)
      .uuid(template?.uuid ?? uuid4())
      .rounds(template?.rounds ?? [])
      .points(template?.points ?? [])
      .pointsHistory(template?.pointsHistory ?? [])
      .ended(template?.ended ?? false)
      .isNPCGame(template?.isNPCGame ?? false)
      .messages(template?.messages ?? [])
      .startedAt(template?.startedAt ?? new Date())
      .players(template?.players ?? []);
  }
}
