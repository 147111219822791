export function arrayRotate(array: any[], n: number): any[] {
  let arr = array.slice();
  arr.unshift.apply(arr, arr.splice(n, arr.length));
  return arr;
}

/**
 * Gets username from userid
 * @param {Number} uid Userid
 * @param {any[]} players players
 * @returns {string} username
 */
export function getUsernameFromUserID(userID: number, players: any[]): string {
  return players[players.map((el) => el.userID).indexOf(userID)].username;
}
