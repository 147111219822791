import { IBuilder, Builder } from 'builder-pattern';
import { AvatarType } from '../../types';

export interface UserInfoInterface {
  userID: number;
  username: string;
  profilePicture: string;
  smallProfilePicture: string;

  // statistics
  glicko2Rating: number;
  points: number;
  allGames: number;
  wonGames: number;
  lostGames: number;
  leftGames: number;
  wonSchneider: number;
  lostSchneider: number;
  wonRetourschneider: number;
  lostRetourschneider: number;

  // flags
  isFriend: boolean;
  isEnemy: boolean;
  hasDonated: boolean;
  hasEnemiedMe: boolean;
  isGoogleLogin: boolean; // just for me via player-info endpoint
  isFriendshipRequest: boolean; // true if the user has gotten a friendship request from me
  hasSentFriendshipRequest: boolean; // true if the user has sent me a friendship request

  email: string; // just for me via player-info endpoint
  lastUsernameChange: Date | undefined; //  // just for me via player-info endpoint
  donationSum: number; // just for me via player-info endpoint

  tableID: number; // not always!
  isInGame: boolean; // not always!
  table: any | undefined; // not always!
  watchingTableID: number; // not always!

  // just for messages
  hasNoUnreadMessages: boolean;
  hasUnreadMessages: boolean;

  registrationDate: Date;

  avatar: AvatarType;
}

export namespace UserInfoInterface {
  export function builder(
    template?: Partial<UserInfoInterface>
  ): IBuilder<UserInfoInterface> {
    return Builder<UserInfoInterface>(template);
  }
}
