import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GameEventService {
  private gameEventSource: Subject<{ name: string; data: any }> = new Subject<{
    name: string;
    data: any;
  }>();
  public gameEvent$: Observable<{ name: string; data: any }>;

  constructor() {
    this.gameEvent$ = this.gameEventSource.asObservable();
  }

  public emit(v: { name: string; data: any }): void {
    this.gameEventSource.next(v);
  }
}
