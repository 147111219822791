export type AvatarType =
  | 'man-1'
  | 'man-2'
  | 'man-3'
  | 'man-4'
  | 'woman-1'
  | 'woman-2';

export const avatarTypes: AvatarType[] = [
  'man-1',
  'man-2',
  'man-3',
  'man-4',
  'woman-1',
  'woman-2',
];

export function getNextAvatar(avatar: AvatarType): AvatarType {
  const idx = avatarTypes.indexOf(avatar);
  return avatarTypes[(idx + 1) % avatarTypes.length];
}

export function getPrevAvatar(avatar: AvatarType): AvatarType {
  const idx = avatarTypes.indexOf(avatar);
  if (idx === 0) return avatarTypes[avatarTypes.length - 1];
  return avatarTypes[idx - 1];
}
