import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpBackendService } from 'src/app/core/backend-services/http/http.backend-service';
import { GameInterface } from '../../interfaces';
import { AnnouncementType, CardColorType } from '../../types';

@Injectable({
  providedIn: 'root',
})
export class NpcGameBackendService {
  private readonly BASE_ADDRESS = '/game/npc';

  /**
   * @description constructor of NpcGameBackendService
   * @param {HttpBackendService} http - http backend service
   */
  constructor(private http: HttpBackendService) {}

  /**
   * @description creates a new npc game
   * @param {number} numPlayers
   * @param {any} cardsOverride can be used to override the cards
   * @returns {Promise<GameInterface>}
   */
  public newGame(
    numPlayers: number,
    cardsOverride: any = undefined
  ): Promise<GameInterface> {
    return firstValueFrom(
      this.http.post<GameInterface>(`${this.BASE_ADDRESS}/new`, {
        numPlayers,
        cards: cardsOverride,
      })
    );
  }

  /**
   * @description creates a new npc game
   * @param {GameInterface} game
   * @returns {Promise<GameInterface>}
   */
  public npcMove(game: GameInterface): Promise<GameInterface> {
    return firstValueFrom(
      this.http.post<GameInterface>(`${this.BASE_ADDRESS}/move`, { game })
    );
  }

  /**
   * @description plays a card
   * @param {GameInterface} game
   * @param {number} cardNumber
   * @returns {Promise<GameInterface>}
   */
  public playCard(
    game: GameInterface,
    cardNumber: number
  ): Promise<GameInterface> {
    return firstValueFrom(
      this.http.post<GameInterface>(`${this.BASE_ADDRESS}/play-card`, {
        game,
        cardNumber,
      })
    );
  }

  /**
   * @description gives a card
   * @param {GameInterface} game
   * @param {number} cardNumber
   * @returns {Promise<GameInterface>}
   */
  public giveCard(
    game: GameInterface,
    cardNumber: number
  ): Promise<GameInterface> {
    return firstValueFrom(
      this.http.post<GameInterface>(`${this.BASE_ADDRESS}/give-card`, {
        game,
        cardNumber,
      })
    );
  }

  /**
   * @description does a marriage
   * @param {GameInterface} game
   * @param {number} cardNumber
   * @returns {Promise<GameInterface>}
   */
  public marriage(
    game: GameInterface,
    cardNumber: number
  ): Promise<GameInterface> {
    return firstValueFrom(
      this.http.post<GameInterface>(`${this.BASE_ADDRESS}/marriage`, {
        game,
        cardNumber,
      })
    );
  }

  /**
   * @description changes atout card
   * @param {GameInterface} game
   * @returns {Promise<GameInterface>}
   */
  public changeAtoutCard(game: GameInterface): Promise<GameInterface> {
    return firstValueFrom(
      this.http.post<GameInterface>(`${this.BASE_ADDRESS}/change-atout-card`, {
        game,
      })
    );
  }

  /**
   * @description does zuadrahn
   * @param {GameInterface} game
   * @returns {Promise<GameInterface>}
   */
  public zuadrahn(game: GameInterface): Promise<GameInterface> {
    return firstValueFrom(
      this.http.post<GameInterface>(`${this.BASE_ADDRESS}/zuadrahn`, {
        game,
      })
    );
  }

  /**
   * @description does look talon
   * @param {GameInterface} game
   * @returns {Promise<GameInterface>}
   */
  public lookTalon(
    game: GameInterface,
    myCardsToGiveAway: number[],
    cardsIWantToHave: number[]
  ): Promise<GameInterface> {
    return firstValueFrom(
      this.http.post<GameInterface>(`${this.BASE_ADDRESS}/look-talon`, {
        game,
        myCardsToGiveAway,
        cardsIWantToHave,
      })
    );
  }

  /**
   * @description does shout
   * @param {GameInterface} game
   * @param {CardColorType|undefined} color
   * @returns {Promise<GameInterface>}
   */
  public shout(
    game: GameInterface,
    color: CardColorType | undefined
  ): Promise<GameInterface> {
    return firstValueFrom(
      this.http.post<GameInterface>(`${this.BASE_ADDRESS}/shout`, {
        game,
        color,
      })
    );
  }

  /**
   * @description does announce
   * @param {GameInterface} game
   * @param {AnnouncementType} announcement
   * @returns {Promise<GameInterface>}
   */
  public announce(
    game: GameInterface,
    announcement: AnnouncementType
  ): Promise<GameInterface> {
    return firstValueFrom(
      this.http.post<GameInterface>(`${this.BASE_ADDRESS}/announce`, {
        game,
        announcement,
      })
    );
  }

  /**
   * @description does decide kontra
   * @param {GameInterface} game
   * @param {boolean} doContra
   * @param {boolean} re
   * @returns {Promise<GameInterface>}
   */
  public decideKontra(
    game: GameInterface,
    doContra: boolean,
    re: boolean
  ): Promise<GameInterface> {
    return firstValueFrom(
      this.http.post<GameInterface>(`${this.BASE_ADDRESS}/decide-kontra`, {
        game,
        doContra,
        re,
      })
    );
  }
}
