import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { wait } from 'src/app/core/util';

@Injectable({
  providedIn: 'root',
})
export class AppExtensionsService {
  isRunningInApp: boolean = false;

  lastAdShown: Date = new Date();
  readonly adInterval = 1000 * 90;

  constructor(private settingsService: SettingsService) {}

  public init() {
    this.isRunningInApp =
      (window as any).isRunningInAndroidApp ||
      (window as any).isRunningIniOSApp;
  }

  public async rumble(ms: number = 500): Promise<void> {
    const settings = this.settingsService.settings;
    if (!settings.vibrationEnabled) return;

    if (navigator.vibrate) navigator.vibrate(ms);
    else this.call(`rumble://${ms}`);
  }

  public async getUUID(): Promise<string | undefined> {
    this.call(`get-uuid://`);
    await wait(200);
    return (window as any).uuid || undefined;
  }

  public reset(): void {
    this.call(`reset://`);
  }

  public showLoading(): void {
    this.call(`spinneron://`);
  }

  public hideLoading(): void {
    this.call(`spinneroff://`);
  }

  public showAd(): void {
    const settings = this.settingsService.settings;
    if (settings.hasActivePremiumSubscription) return;

    if (Date.now() - this.lastAdShown.getTime() < this.adInterval) return;

    console.log('showing ad');

    this.call(`admob://`);
    this.lastAdShown = new Date();
  }

  private call(url: string): void {
    if (!this.isRunningInApp) return;
    top.location.href = url;
  }
}
