import { IBuilder, Builder } from 'builder-pattern';

export interface ZuadrahtInterface {
  playerIdx: number;
  myRoundCount: number;
  opponentRoundCount: number;
}

export namespace ZuadrahtInterface {
  export function builder(
    template?: Partial<ZuadrahtInterface>
  ): IBuilder<ZuadrahtInterface> {
    return Builder<ZuadrahtInterface>(template);
  }
}
