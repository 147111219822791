import { CardType } from '../../types';
import { IBuilder, Builder } from 'builder-pattern';

export interface TrickInterface {
  cardsTillNow: { playerIdx: number; card: CardType }[];
  value: number;
  winnerIdx: number;
  marriageValue: number;
  tookFromStaple: { playerIdx: number; card: CardType }[];
}

export namespace TrickInterface {
  export function builder(
    template?: Partial<TrickInterface>
  ): IBuilder<TrickInterface> {
    return Builder<TrickInterface>(template)
      .cardsTillNow(template?.cardsTillNow ?? [])
      .tookFromStaple(template?.tookFromStaple ?? []);
  }
}
