import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/core/services';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private popupWindow: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private utilService: UtilService
  ) {}

  public async startGame(): Promise<void> {
    if (await this.authService.isAuthenticated()) {
      if (this.popupWindow) {
        this.popupWindow.close();
      }
      if (this.utilService.isMobile()) {
        this.router.navigate(['/spielen']);
      } else {
        this.popupWindow = window.open(
          '/spielen',
          '_blank',
          'resizable=yes, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=965, height=750'
        );
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  public showPlayerInfo(userID: number) {
    if (this.utilService.isMobile()) {
      this.router.navigateByUrl('/profil/' + userID);
    } else {
      window.open('/profil/' + userID, '_blank');
    }
  }
}
