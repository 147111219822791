import { IBuilder, Builder } from 'builder-pattern';

export interface UserFlagsInterface {
  isAdministrator: boolean;
  hasDonated: boolean;
  isPaidSubscriber: boolean;
  isPaidPremiumSubscriber: boolean;
  isLocked: boolean;
  hasEmailConfirmed: boolean;
}

export namespace UserFlagsInterface {
  export function builder(
    template?: Partial<UserFlagsInterface>
  ): IBuilder<UserFlagsInterface> {
    return Builder<UserFlagsInterface>(template);
  }

  export function getDefault(): UserFlagsInterface {
    return {
      isAdministrator: false,
      hasDonated: false,
      isPaidSubscriber: false,
      isPaidPremiumSubscriber: false,
      isLocked: false,
      hasEmailConfirmed: false,
    };
  }
}
