/**
 * @file This is the browser tools module
 * @copyright Andreas Horvath 2016-2024
 * @version 0.2.0
 */

import { UAParser } from 'ua-parser-js';

export function isMobile(): boolean {
  return !window.matchMedia(
    'only screen and (min-width: 790px) and (min-height: 590px)'
  ).matches;
}

export function getBrowserDetails() {
  const parser = new UAParser();
  return parser.getResult();
}

/**
 * gets console outputs
 * @returns {Object.<Array>}
 */
export function getConsoleOutputs() {
  return {
    errors: JSON.parse(JSON.stringify((console as any).errors)),
    warns: JSON.parse(JSON.stringify((console as any).warns)),
    logs: JSON.parse(JSON.stringify((console as any).logs)),
    infos: JSON.parse(JSON.stringify((console as any).infos)),
  };
}

export function getWindowSize() {
  return {
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
  };
}

/**
 * installs custom console.log handlers
 */
export function hookConsole() {
  if ((window as any).consoleAlreadyHooked) return;

  (console as any).defaultLog = (console as any).log.bind(console);
  (console as any).logs = [];
  (console as any).log = function () {
    (console as any).defaultLog.apply(console, arguments);
    (console as any).logs.push({
      date: new Date().toLocaleString(),
      args: Array.from(arguments),
    });
  };

  (console as any).defaultWarn = (console as any).warn.bind(console);
  (console as any).warns = [];
  (console as any).warn = function () {
    (console as any).defaultWarn.apply(console, arguments);
    (console as any).warns.push({
      date: new Date().toLocaleString(),
      args: Array.from(arguments),
    });
  };

  (console as any).defaultError = (console as any).error.bind(console);
  (console as any).errors = [];
  (console as any).error = function () {
    (console as any).defaultWarn.apply(console, arguments); // change errors to warns
    (console as any).errors.push({
      date: new Date().toLocaleString(),
      args: Array.from(arguments),
    });
  };

  (console as any).defaultDebug = (console as any).debug.bind(console);
  (console as any).debugs = [];
  (console as any).debug = function () {
    (console as any).defaultDebug.apply(console, arguments);
    (console as any).debugs.push({
      date: new Date().toLocaleString(),
      args: Array.from(arguments),
    });
  };

  (console as any).defaultInfo = (console as any).info.bind(console);
  (console as any).infos = [];
  (console as any).info = function () {
    (console as any).defaultInfo.apply(console, arguments);
    (console as any).infos.push({
      date: new Date().toLocaleString(),
      args: Array.from(arguments),
    });
  };

  (window as any).consoleAlreadyHooked = true;
}
