export type CardType =
  | 'HA'
  | 'HZ'
  | 'HK'
  | 'HD'
  | 'HB'
  | 'KA'
  | 'KZ'
  | 'KK'
  | 'KD'
  | 'KB'
  | 'PA'
  | 'PZ'
  | 'PK'
  | 'PD'
  | 'PB'
  | 'EA'
  | 'EZ'
  | 'EK'
  | 'ED'
  | 'EB';

export namespace CardType {
  export const cards: CardType[] = [
    ,
    'HA',
    'HZ',
    'HK',
    'HD',
    'HB',
    'KA',
    'KZ',
    'KK',
    'KD',
    'KB',
    'PA',
    'PZ',
    'PK',
    'PD',
    'PB',
    'EA',
    'EZ',
    'EK',
    'ED',
    'EB',
  ];
}
