import { arrayRotate } from '..';
import { AnnouncementType, CardColorType, CardType } from '../../types';

const suits = [
  { suit: 'H', color: 'red' },
  { suit: 'P', color: 'black' },
  { suit: 'K', color: 'red' },
  { suit: 'E', color: 'black' },
];

/**
 * @description sorts the cards by color and value
 * @param {CardType[]} cardsArray
 * @param {CardColorType} atout
 * @param {AnnouncementType | undefined} announcement
 * @returns {CardType[]}
 */
export function sortCards(
  cardsArray: CardType[],
  atout: CardColorType = 'H',
  announcement: AnnouncementType | undefined
) {
  let cardsArrayCopy = cardsArray.slice();

  let suitsOrder = arrayRotate(
    ['H', 'P', 'K', 'E'],
    ['H', 'P', 'K', 'E'].indexOf(atout)
  );

  const colorSortFunction = (a, b) => {
    if (suitsOrder.indexOf(getColor(a)) < suitsOrder.indexOf(getColor(b)))
      return -1;
    if (suitsOrder.indexOf(getColor(a)) > suitsOrder.indexOf(getColor(b)))
      return 1;
    if (suitsOrder.indexOf(getColor(a)) === suitsOrder.indexOf(getColor(b)))
      return 0;
  };

  cardsArrayCopy.sort(colorSortFunction);
  cardsArrayCopy.sort((a, b) => {
    if (getColor(a) !== getColor(b)) return 0; // dont touch if not same color

    const aValue = announcement
      ? getEffectiveValue(a, announcement)
      : getValue(a);
    const bValue = announcement
      ? getEffectiveValue(b, announcement)
      : getValue(b);

    if (aValue < bValue) return 1;
    if (aValue > bValue) return -1;
    if (aValue === bValue) return 0;
  });

  return cardsArrayCopy;
}

function getColor(card: CardType): CardColorType {
  return card.slice(0, 1) as CardColorType;
}

function getValue(card: CardType): CardColorType {
  const cardValues = {
    B: 2,
    D: 3,
    K: 4,
    Z: 10,
    A: 11,
  };
  return cardValues[card.slice(1, 2)];
}

function getEffectiveValue(
  card: CardType,
  roundGame: AnnouncementType
): CardColorType {
  const cardValuesZehnerloch = { B: 3, D: 4, K: 10, Z: 11, A: 2 };
  if (roundGame === 'Zehnerloch') return cardValuesZehnerloch[card.slice(1, 2)];
  else return getValue(card);
}
