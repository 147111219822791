/**
 * @file This is the auth service module
 * @copyright Andreas Horvath 2016-2024
 * @version 0.2.0
 */
import { Injectable } from '@angular/core';
import { AuthBackendService } from '../../backend-services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { MyUserService } from '../my-user/my-user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isAuthenticatedSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isAuthenticated$: Observable<boolean> =
    this.isAuthenticatedSource.asObservable();

  private hasLoaded: boolean = false;
  private isLoggedIn: boolean = false;

  constructor(
    private authBackendService: AuthBackendService,
    private router: Router,
    private toastr: ToastrService,
    private myUserService: MyUserService
  ) {}

  public async login(username: string, password: string): Promise<void> {
    if (this.hasLoaded && this.isLoggedIn) return;
    try {
      await this.authBackendService.login(username, password);
      this.hasLoaded = true;
      this.isLoggedIn = true;
      this.isAuthenticatedSource.next(true);
      await this.router.navigateByUrl('/logged-in');
      this.myUserService.update();
    } catch (e) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/login'], {
          queryParams: {
            usernameOrPasswordWrong: e.status === 400,
            username: username,
            error: e.status !== 400,
          },
        });
      });
    }
  }

  public async logout(): Promise<void> {
    if (this.hasLoaded && !this.isLoggedIn) return;
    await this.authBackendService.logout();
    this.isLoggedIn = false;
    this.hasLoaded = false;
    this.isAuthenticatedSource.next(false);
    this.router.navigate(['/'], {
      queryParams: { logout: true },
    });
    this.myUserService.update();
  }

  public async isAuthenticated(): Promise<boolean> {
    if (this.hasLoaded) return this.isLoggedIn;
    const res = await this.authBackendService.checkLoggedIn();
    this.isLoggedIn = res;
    this.hasLoaded = true;
    this.isAuthenticatedSource.next(res);
    return res;
  }
}
