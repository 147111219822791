import { Injectable } from '@angular/core';
import { CardDisplayType } from 'src/app/core/types';

export const DEFAULT_CARD_DISPLAY_TYPE: CardDisplayType = 'doppeldeutsch';

@Injectable({
  providedIn: 'root',
})
export class CardDisplayTypeService {
  /**
   * CardDisplayTypeService constructor.
   */
  constructor() {
    const localStorageValue = localStorage.getItem('cardDisplayType');
    CardDisplayType.configuredCardDisplayType = [
      'doppeldeutsch',
      'französisch',
    ].includes(localStorageValue)
      ? (localStorageValue as CardDisplayType)
      : DEFAULT_CARD_DISPLAY_TYPE;
  }

  /**
   * Get the current card display type.
   * @returns {CardDisplayType} The current card display type.
   */
  currentCardDisplayType(): CardDisplayType {
    return CardDisplayType.configuredCardDisplayType;
  }

  /**
   * Change the card display type.
   * @param {CardDisplayType} v - The new card display type.
   * @returns {void}
   */
  changeCardDisplayType(v: CardDisplayType): void {
    CardDisplayType.configuredCardDisplayType = v;
    localStorage.setItem(
      'cardDisplayType',
      CardDisplayType.configuredCardDisplayType
    );
  }

  /**
   * Toggle the card display type between 'doppeldeutsch' and 'französisch'.
   * @returns {void}
   */
  toggleCardDisplayType(): void {
    if (this.currentCardDisplayType() === 'doppeldeutsch')
      this.changeCardDisplayType('französisch');
    else this.changeCardDisplayType('doppeldeutsch');
  }
}
