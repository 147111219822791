import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NPCGameService } from './npc-game.service';
import { ReplayService } from './replay.service';
import { MyPlayerService } from './my-player.service';
import { TableStatusEnum } from 'src/app/core/enums';
import { SocketIoService } from 'src/app/core/services';
import { showConfirmationPrompt } from 'src/app/core/util';
import { BehaviorSubject } from 'rxjs';
import { GameStateService } from './game-state.service';
import { LobbyService } from './lobby.service';
import * as SoundService from 'src/app/core/services/sound/sound.service';
import { InfoDialogService } from './info-dialog.service';
import { GameEventService } from './game-event.service';
import { PlayerInterface } from 'src/app/core/interfaces';
import { ToastrService } from 'ngx-toastr';
import { AppExtensionsService } from './app-extensions.service';

@Injectable({
  providedIn: 'root',
})
export class GameShellService {
  private hasLoadedSource: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public hasLoaded$ = this.hasLoadedSource.asObservable();
  private currentViewSource: BehaviorSubject<'game' | 'lobby'> =
    new BehaviorSubject('lobby');
  public currentView$ = this.currentViewSource.asObservable();
  private leaveTableMarkedSource: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  public leaveTableMarked$ = this.leaveTableMarkedSource.asObservable();

  constructor(
    private route: ActivatedRoute,
    private npcGameService: NPCGameService,
    private replayService: ReplayService,
    private myPlayer: MyPlayerService,
    private socketIO: SocketIoService,
    private state: GameStateService,
    private infoDialogService: InfoDialogService,
    private lobbyService: LobbyService,
    private gameEventService: GameEventService,
    private appExtensionsService: AppExtensionsService,
    private toastr: ToastrService
  ) {}

  public get currentView(): 'game' | 'lobby' {
    return this.currentViewSource.getValue();
  }

  public get hasLoaded(): boolean {
    return this.hasLoadedSource.getValue();
  }

  public get leaveTableMarked(): boolean {
    return this.leaveTableMarkedSource.getValue();
  }

  public async init(): Promise<void> {
    if (!this.hasLoaded) {
      this.setupHandlers();
      this.hasLoadedSource.next(true);
    }

    if (
      this.myPlayer.player.tableID &&
      this.myPlayer.player.table &&
      this.myPlayer.player.table.status === TableStatusEnum.RUNNING
    ) {
      await this.reEnter();
    } else if (this.route.snapshot.queryParamMap.get('replay')) {
      this.showGame();
      this.replayService.replayRound(
        this.route.snapshot.queryParamMap.get('replay')
      );
    } else if (this.myPlayer.player.watchingTableID) {
      await this.reEnterSpectatorGame();
    } else {
      // TODO check if was in a game and set true if it was
      this.showLobby();
    }
  }

  public async startNPCGame(numPlayers: 2 | 3 | 4 = 2) {
    this.showGame();
    await this.npcGameService.playNPCGame(numPlayers);
  }

  public async checkAndLeaveGameTable() {
    if (this.npcGameService.isRunning) {
      this.npcGameService.endGame();
      this.showLobby();
      this.leaveTableMarkedSource.next(false);
    } else {
      let shouldPunishServer = false;
      try {
        shouldPunishServer = await this.socketIO.get('game/check-leave-punish');
      } catch (e) {
        console.info(e);
      }

      if (!shouldPunishServer && this.state.gameWillEnd) {
        await this.leaveGameTable();
        return;
      } else if (
        !shouldPunishServer &&
        !this.leaveTableMarkedSource.getValue()
      ) {
        const confirm = await showConfirmationPrompt(
          `Willst du den Tisch sofort verlassen?`
        );
        if (confirm) {
          await this.leaveGameTable();
          return;
        }
      }

      this.leaveTableMarkedSource.next(!this.leaveTableMarkedSource.getValue());
      if (this.leaveTableMarkedSource.getValue()) {
        this.toastr.success(
          'Du wirst nach dem aktuellen Bummerl den Tisch verlassen.'
        );
      } else {
        this.toastr.success('Du bleibst im Spiel. Viel Erfolg!');
      }
    }
  }

  public async leaveGameTable() {
    await this.lobbyService.leaveTable();
    this.leaveTableMarkedSource.next(false);
  }

  private showLobby(silent: boolean = false) {
    console.log('Showing lobby.');
    this.currentViewSource.next('lobby');
    SoundService.stopAllSounds();
    if (!silent) SoundService.playRandomSFX('goodbye');
    this.infoDialogService.hideInfoDialog();
    this.gameEventService.emit({ name: 'enteredLobby', data: {} });

    if (!silent)
      setTimeout(() => {
        if (!this.lobbyService.sitsOnTable()) {
          this.appExtensionsService.showAd();
        }
      }, 500);
  }

  private showGame(silent: boolean = false) {
    this.leaveTableMarkedSource.next(false);
    console.log('Showing game.');
    this.currentViewSource.next('game');
    if (!silent) SoundService.playRandomSFX('servus');
    this.infoDialogService.hideInfoDialog();
  }

  private setupHandlers() {
    this.socketIO.on('gameStart', () => {
      this.state.reset(true);
      this.state.setPlayers(
        this.myPlayer.player.table.members,
        this.myPlayer.player.table.members
          .map((el) => el.userID)
          .indexOf(this.myPlayer.player.userID)
      );
      this.showGame();
    });
    this.socketIO.on('spectatingStarted', (data) => {
      this.state.reset(true);
      this.state.isSpectatorGame = true;
      this.state.setPlayers(data.data.table.members, -1);
      this.showGame();
    });
    this.socketIO.on('areYouStillOnline', async () => {
      try {
        await this.socketIO.post('offline/challenge/confirm');
      } catch (e) {}
    });
    this.socketIO.on('endGameUserLeft', async (data) => {
      try {
        await this.endGameUserLeft(data.data.player);
      } catch (e) {
        console.warn('endGameUserLeft handler failed:', e);
      }
    });
    this.socketIO.on('tableUpdate', async (data) => {
      if (this.currentView === 'game' && this.state.isSpectatorGame) return;

      try {
        data = data.data;
        await this.myPlayer.setMyTable(data.table);
        if (data.newPlayer) {
          if (data.newPlayer.userID === this.myPlayer.player.userID) {
            console.log('You sat down table');
            this.gameEventService.emit({
              name: 'satDownTable',
              data: data.newPlayer,
            });
          } else {
            console.log('Someone sat down table', data.newPlayer.username);
          }
        }
        if (data.removedPlayer) {
          if (data.removedPlayer.userID === this.myPlayer.player.userID) {
            console.log('You left table');
            this.gameEventService.emit({
              name: 'leftTable',
              data: data.removedPlayer,
            });
          } else {
            console.log('Someone left table', data.removedPlayer.username);
          }

          if (this.currentView === 'game') {
            // when current view is table (game) view and a user leaves the table, go back to room view
            // this is normally handled by the endGameUserLeft handler, however if no game is being played,
            // but the user is still in table view when a player on that table leaves, the view should
            // go back to rooms view

            // just call the endGameUserLeft handler
            await this.endGameUserLeft(data.removedPlayer);
          }
        }
      } catch (e) {
        console.warn('tableUpdate handler failed:', e);
      }
    });
  }

  private async endGameUserLeft(player: PlayerInterface): Promise<void> {
    this.infoDialogService.showInfoDialog(
      'Das Spiel wurde beendet.',
      `${player.username} hat das Spiel verlassen.`
    );

    await this.myPlayer.update();

    if (this.currentView === 'game') {
      this.showLobby();
    }
  }

  private async reEnter(): Promise<void> {
    await this.socketIO.post('game/re-enter');
  }

  private async reEnterSpectatorGame(): Promise<void> {
    await this.socketIO.post('game/watch', {
      tableID: this.myPlayer.player.watchingTableID,
    });
  }

  public async refreshGameAfterDisconnect(): Promise<void> {
    this.showLobby(true);
    await this.reEnter();
  }
}
