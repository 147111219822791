import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  Observable,
  concatMap,
  delay,
  iif,
  of,
  retryWhen,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';

export interface RetryRequestOptions {
  maximumRetries: number;
  retryDelay: number;
}

/**
 * Prefixes all requests with `environment.SERVER_URL`. and adds withCredentials and adds csrf token header
 */
@Injectable({
  providedIn: 'root',
})
export class CustomInterceptor implements HttpInterceptor {
  private retryRequestOptions: RetryRequestOptions = {
    maximumRetries: 2,
    retryDelay: 200, //milliseconds
  };

  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!/^(http|https):/i.test(request.url)) {
      request = request.clone({
        url: environment.API_SERVER_URL + request.url,
      });
    }

    const cookieheaderName = 'X-CSRF-Token';
    let csrfToken = (window as any).csrfToken || null;
    if (csrfToken !== null) {
      request = request.clone({
        headers: request.headers.set(cookieheaderName, csrfToken),
      });
    }

    return next.handle(request);
  }

  retryPipe<T>() {
    return retryWhen<T>((errors: Observable<HttpErrorResponse>) =>
      errors.pipe(
        // Use concat map to keep the errors in order and make sure they
        // aren't executed in parallel
        concatMap((error: HttpErrorResponse, retryId: number) =>
          // Executes a conditional Observable depending on the result
          // of the first argument
          iif(
            () =>
              retryId >= this.retryRequestOptions.maximumRetries ||
              error.status <= 500,
            // If the condition is true we throw the error (the last error)
            this.returnError(error),
            // Otherwise we pipe this back into our stream and delay the retry
            this.retryError(error, retryId, this.retryRequestOptions.retryDelay)
          )
        )
      )
    );
  }

  returnError(error: HttpErrorResponse) {
    return throwError(error);
  }

  retryError(error: HttpErrorResponse, retryId: number, retryDelay: number) {
    // retry request with increasing delay between each attemp
    return of(error).pipe(delay(retryDelay * retryId));
  }
}
