import { CardDisplayType } from '..';

export type CardColorType = 'H' | 'E' | 'K' | 'P';

export namespace CardColorType {
  export function cardColors(): CardColorType[] {
    return ['H', 'E', 'K', 'P'];
  }

  export function getLabel(cardColor: CardColorType): string {
    if (CardDisplayType.configuredCardDisplayType === 'französisch') {
      if (cardColor === 'H') return 'Herz';
      if (cardColor === 'K') return 'Karo';
      if (cardColor === 'P') return 'Pik';
      if (cardColor === 'E') return 'Kreuz';
    } else {
      if (cardColor === 'H') return 'Herz';
      if (cardColor === 'K') return 'Schelle';
      if (cardColor === 'P') return 'Laub';
      if (cardColor === 'E') return 'Eichel';
    }
  }
}
