import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpBackendService } from 'src/app/core/backend-services/http/http.backend-service';

@Injectable({
  providedIn: 'root',
})
export class LobbyBackendService {
  private readonly BASE_ADDRESS = '/game/table';

  /**
   * @description constructor of LobbyBackendService
   * @param {HttpBackendService} http - http backend service
   */
  constructor(private http: HttpBackendService) {}

  /**
   * @description gets tables list
   * @returns {Promise<any[]>}
   */
  public getTables(): Promise<any[]> {
    return firstValueFrom(this.http.get<any[]>(`${this.BASE_ADDRESS}`));
  }
}
