import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpBackendService } from 'src/app/core/backend-services/http/http.backend-service';

@Injectable({
  providedIn: 'root',
})
export class ProfileBackendService {
  private readonly BASE_ADDRESS = '/profile';

  /**
   * @description constructor of ProfileBackendService
   * @param {HttpBackendService} http - http backend service
   */
  constructor(private http: HttpBackendService) {}

  /**
   * @description uploads profile picture
   * @param {any} blob
   * @returns {Promise<void>}
   */
  public uploadProfilePicture(blob: any): Promise<void> {
    const data = new FormData();
    data.append('profilePicture', blob);
    return firstValueFrom(
      this.http.post<void>(`${this.BASE_ADDRESS}/upload-profile-picture`, data)
    );
  }
}
