declare var UIkit: any;

export function showConfirmationPrompt(text: string): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    UIkit.modal.confirm(text, { i18n: { ok: 'JA', cancel: 'NEIN' } }).then(
      () => resolve(true),
      () => resolve(false)
    );
  });
}
