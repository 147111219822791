import { Injectable } from '@angular/core';
import { NpcGameBackendService } from 'src/app/core/backend-services';
import { GameInterface, PlayerInterface } from 'src/app/core/interfaces';
import { AnnouncementType, CardColorType } from 'src/app/core/types';
import { wait } from 'src/app/core/util';
import * as SoundService from 'src/app/core/services/sound/sound.service';
import { GameStateService } from './game-state.service';
import { GameEventService } from './game-event.service';
import { MyPlayerService } from './my-player.service';

@Injectable({
  providedIn: 'root',
})
export class NPCGameService {
  private player1: PlayerInterface = PlayerInterface.builder()
    .username('Ich')
    .build();
  private player2: PlayerInterface = PlayerInterface.builder()
    .username('Franz')
    .userID(-10)
    .avatar('man-2')
    .smallProfilePicture('/assets/images/icons/computer.png')
    .isNPC(true)
    .build();
  private player3: PlayerInterface = PlayerInterface.builder()
    .username('Martina')
    .userID(-11)
    .avatar('woman-1')
    .smallProfilePicture('/assets/images/icons/computer.png')
    .isNPC(true)
    .build();
  private player4: PlayerInterface = PlayerInterface.builder()
    .username('Robert')
    .userID(-12)
    .avatar('man-1')
    .smallProfilePicture('/assets/images/icons/computer.png')
    .isNPC(true)
    .build();

  private game: GameInterface = GameInterface.builder().build();
  private timeoutHandle: any;
  private gameRunning: boolean = false;

  constructor(
    private npcGameBackendService: NpcGameBackendService,
    private state: GameStateService,
    private gameEventService: GameEventService,
    private myPlayerService: MyPlayerService
  ) {}

  public async playNPCGame(numPlayers: 2 | 3 | 4): Promise<void> {
    this.gameRunning = true;
    this.state.isNPCGame = true;
    this.state.setPlayers(
      [
        this.myPlayerService.player,
        this.player2,
        this.player3,
        this.player4,
      ].slice(0, numPlayers),
      0
    );

    this.sendGameEventsNPCGame();

    while (this.gameRunning) {
      console.log('Starting new NPC game.');
      this.game = await this.npcGameBackendService.newGame(
        numPlayers,
        (window as any).cardsOverride
      );
      this.game.players = JSON.parse(JSON.stringify(this.state.players));

      while (this.gameRunning && !this.game.ended) {
        if (this.state.isNPCRoundActive) {
          let timeout =
            this.state.NPCRoundJustStarted && this.game.rounds.length > 1
              ? 7000
              : 1000;
          if (
            this.game.rounds[this.game.rounds.length - 1].waitingFor
              .playerIdx !== 0 &&
            this.game.rounds[this.game.rounds.length - 1].waitingFor.timestamp +
              timeout <
              Date.now()
          ) {
            this.game = await this.npcGameBackendService.npcMove(this.game);
            this.state.madeNPCBackendCall();
          }
        }
        await wait(100);
      }
      if (!this.gameRunning) break;
      await wait(8000);
    }
  }

  public get isRunning(): boolean {
    return this.gameRunning;
  }

  public endGame(): void {
    if (!this.gameRunning) return;
    clearTimeout(this.timeoutHandle);
    this.gameRunning = false;
    this.state.setPlayers([], 0);
    this.state.reset(true);
    SoundService.stopAllSounds();
  }

  private async sendGameEventsNPCGame(): Promise<void> {
    for (let message of this.game.messages
      .filter((el) => !el.processed)
      .filter(
        (el) => el.receiver === 'ALL' || el.receiver === this.state.myIdx
      )) {
      this.gameEventService.emit({
        name: message.type,
        data: message.data,
      });
      message.processed = true;
      if (message.type === 'marker' && message.data.type === 'roundEnd') {
        await wait(3000);
      }
    }
    this.timeoutHandle = setTimeout(() => {
      this.sendGameEventsNPCGame();
    }, 100);
  }

  public async playCard(cardNumber: number): Promise<void> {
    try {
      this.game = await this.npcGameBackendService.playCard(
        this.game,
        cardNumber
      );
      this.state.madeNPCBackendCall();
    } catch (e) {
      console.warn('NPC play card action failed:', e);
    }
  }

  public async giveCard(cardNumber: number): Promise<void> {
    try {
      this.game = await this.npcGameBackendService.giveCard(
        this.game,
        cardNumber
      );
      this.state.madeNPCBackendCall();
    } catch (e) {
      console.warn('NPC give card action failed:', e);
    }
  }

  public async marriage(cardNumber: number): Promise<void> {
    try {
      this.game = await this.npcGameBackendService.marriage(
        this.game,
        cardNumber
      );
      this.state.madeNPCBackendCall();
    } catch (e) {
      console.warn('NPC marriage action failed:', e);
    }
  }

  public async changeAtoutCard() {
    try {
      this.game = await this.npcGameBackendService.changeAtoutCard(this.game);
      this.state.madeNPCBackendCall();
    } catch (e) {
      console.warn('NPC change atout card action failed:', e);
    }
  }

  public async zuadrahn() {
    try {
      this.game = await this.npcGameBackendService.zuadrahn(this.game);
      this.state.madeNPCBackendCall();
    } catch (e) {
      console.warn('NPC zuadrahn action failed:', e);
    }
  }

  public async lookTalon(
    myCardsToGiveAway: number[],
    cardsIWantToHave: number[]
  ) {
    try {
      this.game = await this.npcGameBackendService.lookTalon(
        this.game,
        myCardsToGiveAway,
        cardsIWantToHave
      );
      this.state.madeNPCBackendCall();
    } catch (e) {
      console.warn('NPC look talon action failed:', e);
    }
  }

  public async shout(color: CardColorType | undefined): Promise<void> {
    try {
      this.game = await this.npcGameBackendService.shout(this.game, color);
      this.state.madeNPCBackendCall();
    } catch (e) {
      console.warn('NPC shout action failed:', e);
    }
  }

  public async announce(announcement: AnnouncementType): Promise<void> {
    try {
      this.game = await this.npcGameBackendService.announce(
        this.game,
        announcement
      );
      this.state.madeNPCBackendCall();
    } catch (e) {
      console.warn('NPC announce action failed:', e);
    }
  }

  public async decideKontra(
    doContra: boolean,
    re: boolean = false
  ): Promise<void> {
    try {
      this.game = await this.npcGameBackendService.decideKontra(
        this.game,
        doContra,
        re
      );
      this.state.madeNPCBackendCall();
    } catch (e) {
      console.warn('NPC decide kontra action failed:', e);
    }
  }
}
