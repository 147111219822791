import { CardColorType } from '../card-color/card-color.type';
import { CardType } from '../card/card.type';

export type CardDisplayType = 'doppeldeutsch' | 'französisch';

export namespace CardDisplayType {
  export var configuredCardDisplayType: CardDisplayType = 'doppeldeutsch';

  export function getCardSrc(card: CardType): string {
    if (configuredCardDisplayType === 'doppeldeutsch')
      return `/assets/images/cards/doppeldeutsch_${card}.png`;
    if (configuredCardDisplayType === 'französisch')
      return `/assets/images/cards/französisch_${card}.svg`;
  }

  export function getCardColorIconSrc(color: CardColorType): string {
    if (configuredCardDisplayType === 'doppeldeutsch') {
      return `/assets/images/card-colors/${color}.png`;
    }
    if (configuredCardDisplayType === 'französisch') {
      if (color === 'H') return `/assets/images/card-colors/herz.svg`;
      if (color === 'P') return `/assets/images/card-colors/pik.svg`;
      if (color === 'K') return `/assets/images/card-colors/karo.svg`;
      if (color === 'E') return `/assets/images/card-colors/kreuz.svg`;
    }
  }
}
