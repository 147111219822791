/**
 * @file This is the my user service module
 * @copyright Andreas Horvath 2016-2024
 * @version 0.2.0
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, firstValueFrom } from 'rxjs';
import { UserInfoInterface } from '../../interfaces';
import { UserBackendService } from '../../backend-services';

@Injectable({
  providedIn: 'root',
})
export class MyUserService {
  private userSource: BehaviorSubject<UserInfoInterface> = new BehaviorSubject(
    UserInfoInterface.builder().build()
  );
  public user$: Observable<UserInfoInterface> = this.userSource.asObservable();

  constructor(private userBackendService: UserBackendService) {
    this.update();
  }

  public async update() {
    try {
      const user = await this.userBackendService.getPlayerInfo();
      this.userSource.next(user);
    } catch (e) {
      console.log('Could not get user info:', e);
    }
  }

  public async getUser(): Promise<UserInfoInterface> {
    return firstValueFrom(
      this.user$.pipe(filter((user) => !!user && !!user.userID))
    );
  }
}
