/**
 * @file This is the sound module
 * @copyright Andreas Horvath 2016-2024
 * @version 0.2.0
 */

let soundsMap: Map<string, { name: string; src: string; speech: boolean }[]> =
  new Map();
let sfxSoundsEnabled: boolean = false;
let speechSoundsEnabled: boolean = false;

let currentlyPlaying: { type?: string; name: string; sound: any }[] = [];

class Sound {
  private _sound: HTMLAudioElement;

  /**
   * creates a new Sound object
   * @param {String} src
   */
  constructor(src) {
    this._sound = document.createElement('audio');
    this._sound.src = src;
    this._sound.setAttribute('preload', 'auto');
    this._sound.setAttribute('controls', 'none');
    this._sound.style.display = 'none';
    document.body.appendChild(this._sound);
  }

  /**
   * checks if playback has ended
   */
  get ended(): boolean {
    return this._sound.ended;
  }

  /**
   * plays the sound
   */
  async play(): Promise<void> {
    try {
      await this._sound.play();
    } catch (e) {
      console.debug('could not play sound ' + this._sound.src + ' :', e);
    }
  }

  /**
   * stops the sound
   */
  stop(): void {
    this._sound.pause();
  }

  /**
   * sets a callback when sound has ended
   * @param {Function} func
   */
  setOnEnded(func: () => {}): void {
    this._sound.onended = func;
  }

  /**
   * sets a callback when sound has ended
   * @param {Function} func
   */
  set onended(func: () => {}) {
    this.setOnEnded(func);
  }

  /**
   * stops the sound and destroys the instance
   */
  destroy(): void {
    this.stop();
    this._sound.parentNode.removeChild(this._sound);
  }
}

/**
 * plays a sound from soundObject, blocking
 * @param {Sound} snd
 * @returns {Promise}
 */
function playSoundObject(snd): Promise<void> {
  return new Promise((resolve, reject) => {
    snd.onended = (() => {
      snd.destroy();
      resolve();
    }) as () => {};
    snd.play();
  });
}

/**
 * registers a sound in the soundsMap
 * @param {String} type the kind of sound this is (shuffle, playcard)
 * @param {String} name the name of this sound
 * @param {String} src the path to the soundfile
 */
function registerSound(type, name, src): void {
  if (!soundsMap.has(type)) soundsMap.set(type, []);
  soundsMap.get(type).push({ name, src, speech: false });
}

/**
 * registers a speech sound in the soundsMap
 * @param {String} type the kind of sound this is (shuffle, playcard)
 * @param {String} name the name of this sound
 * @param {String} src the path to the soundfile
 */
function registerSpeechSound(type, name, src) {
  if (!soundsMap.has(type)) soundsMap.set(type, []);
  soundsMap.get(type).push({ name, src, speech: true });
}

/**
 * returns the sound table
 * @returns {Map}
 */
export function getSoundTable() {
  return soundsMap;
}

/**
 * returns the flat sound table (without types)
 * @returns {Map}
 */
function getFlatSoundTable() {
  let flatMap = new Map();
  for (const el of soundsMap.values()) {
    for (const entry of el) {
      flatMap.set(entry.name, { src: entry.src, speech: entry.speech });
    }
  }
  return flatMap;
}

/**
 * plays a soundeffect from the soundsMap
 * @param {String} name the name of this sound
 * @param {Boolean=} block false for nonblocking
 * @returns {Object=} sound object or null
 */
export async function playSFX(name, block = true) {
  const sfxTable = getFlatSoundTable();
  if (!sfxTable.has(name)) return null;

  if (sfxTable.get(name).speech) {
    if (!speechSoundsEnabled) return null;
  } else {
    if (!sfxSoundsEnabled) return null;
  }

  const snd = new Sound(sfxTable.get(name).src);
  currentlyPlaying.push({ name: name, sound: snd });

  if (block) {
    await playSoundObject(snd);
    currentlyPlaying.splice(
      currentlyPlaying.indexOf({ name: name, sound: snd }),
      1
    );
    return null;
  } else {
    snd.onended = (() => {
      snd.destroy();
      currentlyPlaying.splice(
        currentlyPlaying.indexOf({ name: name, sound: snd }),
        1
      );
    }) as () => {};
    await snd.play();
    return snd;
  }
}

/**
 * plays a random soundeffect from a given type from the soundsMap
 * @param {String} type the kind of sound this is (shuffle, playcard)
 * @param {Boolean=} block false for nonblocking
 * @returns {Object=} sound object or null
 */
export async function playRandomSFX(type, block = true): Promise<Sound | null> {
  const sfxTable = getSoundTable();
  if (!sfxTable.has(type)) return null;

  const getRandomIdx = (len) => Math.floor(Math.random() * len);

  const sounds = sfxTable.get(type);
  const sound = sounds[getRandomIdx(sounds.length)];

  if (sound.speech) {
    if (!speechSoundsEnabled) return null;
  } else {
    if (!sfxSoundsEnabled) return null;
  }

  const snd = new Sound(sound.src);
  currentlyPlaying.push({ type: type, name: sound.name, sound: snd });

  if (block) {
    await playSoundObject(snd);
    currentlyPlaying.splice(
      currentlyPlaying.indexOf({ type: type, name: sound.name, sound: snd }),
      1
    );
    return null;
  } else {
    snd.onended = (() => {
      snd.destroy();
      currentlyPlaying.splice(
        currentlyPlaying.indexOf({ type: type, name: sound.name, sound: snd }),
        1
      );
    }) as () => {};
    await snd.play();
    return snd;
  }
}

export function areSoundsEnabled(): boolean {
  return areSFXSoundsEnabled() || areSpeechSoundsEnabled();
}

export function areSFXSoundsEnabled(): boolean {
  return localStorage.getItem('sfxSoundsEnabled') === 'true';
}

export function areSpeechSoundsEnabled(): boolean {
  return localStorage.getItem('speechSoundsEnabled') === 'true';
}

/**
 * enables all sounds
 */
export function enableSounds(): void {
  enableSFXSounds();
  enableSpeechSounds();
}

/**
 * enables sfx sounds
 */
export function enableSFXSounds(): void {
  sfxSoundsEnabled = true;
  localStorage.setItem('sfxSoundsEnabled', 'true');
}

/**
 * enables speech sounds
 */
export function enableSpeechSounds(): void {
  speechSoundsEnabled = true;
  localStorage.setItem('speechSoundsEnabled', 'true');
}

/**
 * disbles all sounds
 */
export function disableSounds(): void {
  disableSFXSounds();
  disableSpeechSounds();
  stopAllSounds();
}

/**
 * disbles sfx sounds
 */
export function disableSFXSounds(): void {
  sfxSoundsEnabled = false;
  localStorage.setItem('sfxSoundsEnabled', 'false');
}

/**
 * disbles speech sounds
 */
export function disableSpeechSounds(): void {
  speechSoundsEnabled = false;
  localStorage.setItem('speechSoundsEnabled', 'false');
}

/**
 * stops all sounds
 */
export function stopAllSounds(): void {
  currentlyPlaying.forEach((el) => {
    try {
      el.sound.destroy();
    } catch (e) {
      console.debug('could not stop sound:', e);
    }
  });
  currentlyPlaying.splice(0, currentlyPlaying.length);
  currentlyPlaying = [];
}

/**
 * inits module; registers all sounds
 */
export function init() {
  stopAllSounds();
  soundsMap = new Map();
  registerSounds();

  sfxSoundsEnabled = areSFXSoundsEnabled();
  speechSoundsEnabled = areSpeechSoundsEnabled();
}

/**
 * registers all sounds
 */
function registerSounds() {
  registerSound('shuffle', 'shuffle_1', '/assets/sounds/shuffle_1.mp3');
  registerSound('shuffle', 'shuffle_2', '/assets/sounds/shuffle_2.mp3');
  registerSound('play-card', 'play_card_1', '/assets/sounds/play_card_1.mp3');
  registerSound('play-card', 'play_card_4', '/assets/sounds/play_card_4.mp3');
  registerSound('play-card', 'play_card_5', '/assets/sounds/play_card_5.mp3');
  registerSound('play-card', 'play_card_7', '/assets/sounds/play_card_7.mp3');
  registerSound('applause', 'applause_1', '/assets/sounds/applause_1.mp3');
  registerSound('ticking', 'ticking', '/assets/sounds/ticking.mp3');
  registerSound('marriage', 'marriage', '/assets/sounds/marriage.mp3');

  // registerSpeechSound(
  //   'goodbye',
  //   'goodbye_1',
  //   '/assets/sounds/speech/goodbye_1.mp3'
  // );
  // registerSpeechSound(
  //   'servus',
  //   'servus_1',
  //   '/assets/sounds/speech/goodbye_1.mp3'
  // );
  registerSpeechSound('20er', '20er_1', '/assets/sounds/speech/20er_1.mp3');
  registerSpeechSound('20er', '20er_2', '/assets/sounds/speech/20er_2.mp3');
  registerSpeechSound('40er', '40er_1', '/assets/sounds/speech/40er_1.mp3');
  registerSpeechSound('40er', '40er_2', '/assets/sounds/speech/40er_2.mp3');
  registerSpeechSound(
    'ass-bettler',
    'assbettler',
    '/assets/sounds/speech/assbettler.mp3'
  );
  registerSpeechSound(
    'aufschlagen',
    'aufschlogn',
    '/assets/sounds/speech/aufschlogn.mp3'
  );
  registerSpeechSound(
    'bauernschnpser',
    'bauernschnapser',
    '/assets/sounds/speech/bauernschnapser.mp3'
  );
  registerSpeechSound(
    'bauernschnapser',
    'bauernschnapser_spiel',
    '/assets/sounds/speech/bauernschnapser_spiel.mp3'
  );
  registerSpeechSound(
    'bettler',
    'bettler_1',
    '/assets/sounds/speech/bettler_1.mp3'
  );
  registerSpeechSound(
    'bettler',
    'bettler_2',
    '/assets/sounds/speech/bettler_2.mp3'
  );
  registerSpeechSound('eichel', 'eichel', '/assets/sounds/speech/eichel.mp3');
  registerSpeechSound(
    'eichel',
    'eichel_trumpf',
    '/assets/sounds/speech/eichel_trumpf.mp3'
  );
  registerSpeechSound(
    'farbringerl',
    'farbringerl',
    '/assets/sounds/speech/farbringerl.mp3'
  );
  registerSpeechSound('gang', 'gang', '/assets/sounds/speech/gang.mp3');
  registerSpeechSound(
    'gang',
    'gang_ansage',
    '/assets/sounds/speech/gang_ansage.mp3'
  );
  registerSpeechSound('herz', 'herz_1', '/assets/sounds/speech/herz_1.mp3');
  registerSpeechSound('herz', 'herz_2', '/assets/sounds/speech/herz_2.mp3');
  registerSpeechSound(
    'herz',
    'herz_trumpf',
    '/assets/sounds/speech/herz_trumpf.mp3'
  );
  registerSpeechSound('karo', 'karo', '/assets/sounds/speech/karo.mp3');
  registerSpeechSound(
    'karo',
    'karo_trumpf',
    '/assets/sounds/speech/karo_trumpf.mp3'
  );
  registerSpeechSound('kontra', 'kontra', '/assets/sounds/speech/kontra.mp3');
  registerSpeechSound(
    'kontrabauernschnapser',
    'kontrabauernschnapser',
    '/assets/sounds/speech/kontrabauernschnapser.mp3'
  );
  registerSpeechSound(
    'kontraschnapser',
    'kontraschnapser',
    '/assets/sounds/speech/kontraschnapser.mp3'
  );
  registerSpeechSound('kreuz', 'kreuz', '/assets/sounds/speech/kreuz.mp3');
  registerSpeechSound(
    'kreuz',
    'kreuz_trumpf',
    '/assets/sounds/speech/kreuz_trumpf.mp3'
  );
  registerSpeechSound('laub', 'laub', '/assets/sounds/speech/laub.mp3');
  registerSpeechSound(
    'laub',
    'laub_trumpf',
    '/assets/sounds/speech/laub_trumpf.mp3'
  );
  registerSpeechSound(
    'pik',
    'pik_trumpf',
    '/assets/sounds/speech/pik_trumpf.mp3'
  );
  registerSpeechSound(
    'rekontra',
    'rekontra',
    '/assets/sounds/speech/rekontra.mp3'
  );
  registerSpeechSound(
    'schelle',
    'schelle_trumpf',
    '/assets/sounds/speech/schelle_trumpf.mp3'
  );
  registerSpeechSound(
    'schnapser',
    'schnopser_1',
    '/assets/sounds/speech/schnopser_1.mp3'
  );
  registerSpeechSound(
    'schnapser',
    'schnopser_2',
    '/assets/sounds/speech/schnopser_2.mp3'
  );
  registerSpeechSound(
    'trumpffarbringerl',
    'trumpffarbringerl',
    '/assets/sounds/speech/trumpffarbringerl.mp3'
  );
  registerSpeechSound(
    'weiter',
    'weiter_1',
    '/assets/sounds/speech/weiter_1.mp3'
  );
  registerSpeechSound(
    'weiter',
    'weiter_2',
    '/assets/sounds/speech/weiter_2.mp3'
  );
  registerSpeechSound(
    'weiter',
    'weiter_3',
    '/assets/sounds/speech/weiter_3.mp3'
  );
  registerSpeechSound(
    'zehnerloch',
    'zehnerloch',
    '/assets/sounds/speech/zehnerloch.mp3'
  );
  registerSpeechSound(
    'zuadraht',
    'zuadraht',
    '/assets/sounds/speech/zuadraht.mp3'
  );
}
