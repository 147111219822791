import { Injectable } from '@angular/core';
import { GameStateService } from './game-state.service';
import { GameEventService } from './game-event.service';
import { RoundArchiveBackendService } from 'src/app/core/backend-services/round-archive/round-archive.backend-service';
import { wait } from 'src/app/core/util';
import { PlayerInterface } from 'src/app/core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ReplayService {
  private player1: PlayerInterface = PlayerInterface.builder()
    .username('player1')
    .glicko2Rating(1500)
    .build();
  private player2: PlayerInterface = PlayerInterface.builder()
    .username('player2')
    .glicko2Rating(1500)
    .build();
  private player3: PlayerInterface = PlayerInterface.builder()
    .username('player3')
    .glicko2Rating(1500)
    .build();
  private player4: PlayerInterface = PlayerInterface.builder()
    .username('player4')
    .glicko2Rating(1500)
    .build();

  private round: any;

  private _replayRunning: boolean = false;

  constructor(
    private roundArchiveBackendService: RoundArchiveBackendService,
    private state: GameStateService,
    private gameEventService: GameEventService
  ) {}

  public async replayRound(
    uuid: string,
    asPlayerIdx: number = 0
  ): Promise<void> {
    this.round = await this.roundArchiveBackendService.get(uuid);

    this.state.reset(true);
    this.state.isRoundPlaying = true;
    this._replayRunning = true;

    if (
      this.round.players &&
      this.round.players.length === this.round.numPlayers
    ) {
      this.state.setPlayers(this.round.players, asPlayerIdx);
    } else {
      this.state.setPlayers(
        [this.player1, this.player2, this.player3, this.player4].slice(
          0,
          this.round.numPlayers
        ),
        asPlayerIdx
      );
    }

    for (let message of this.round.messages.filter(
      (el) => el.receiver === 'ALL' || el.receiver === this.state.myIdx
    )) {
      if (message.type === 'marker') {
        await wait(1000);
      } else if (
        ['timeoutWarning', 'timedOut', 'didAutoAction'].includes(message.type)
      ) {
        //ignore
      } else {
        this.gameEventService.emit({
          name: message.type,
          data: message.data,
        });
      }
    }

    this._replayRunning = false;
    this.state.isRoundPlaying = false;
  }

  get replayRunning(): boolean {
    return this._replayRunning;
  }
}
