import { Builder, IBuilder } from 'builder-pattern';
import { CardType } from '../../types';

export interface AtoutCardChangedInterface {
  playerIdx: number;
  oldAtoutCard: CardType;
  newAtoutCard: CardType;
}

export namespace AtoutCardChangedInterface {
  export function builder(
    template?: Partial<AtoutCardChangedInterface>
  ): IBuilder<AtoutCardChangedInterface> {
    return Builder<AtoutCardChangedInterface>(template);
  }
}
