import { Injectable } from '@angular/core';
import { MyPlayerService } from './my-player.service';
import { LobbyBackendService } from 'src/app/core/backend-services/lobby/lobby.backend-service';
import { SocketIoService } from 'src/app/core/services';
import { UserBackendService } from 'src/app/core/backend-services';
import { ToastrService } from 'ngx-toastr';
import { TableStatusEnum } from 'src/app/core/enums';
import { UserInfoInterface } from 'src/app/core/interfaces';
import { AppModule } from 'src/app/app.module';

@Injectable({
  providedIn: 'root',
})
export class LobbyService {
  private isInitialized: boolean = false;
  private _tables: any[] = [];
  private _playersOnline: UserInfoInterface[] = [];

  public currentRoom: 'standard' | 'training' | 'gambling' = 'standard';
  public filterNumPlayers: 2 | 3 | 4 = 2;

  constructor(
    private lobbyBackendService: LobbyBackendService,
    private myPlayer: MyPlayerService,
    private socketIO: SocketIoService,
    private userBackendService: UserBackendService,
    private toastr: ToastrService
  ) {}

  public async init(): Promise<void> {
    await this.myPlayer.waitForInit();

    await this.update();
    await this.updateOnlineList();

    if (this.isInitialized) return;
    this.isInitialized = true;

    setInterval(() => {
      this.updateOnlineList();
    }, 20000); // every 20s

    this.socketIO.on('lobbyUpdate', async (data) => {
      data = data.data;
      if (data.newTable) {
        this._tables.push(data.newTable);
      }
      if (data.deletedTable) {
        this._tables.splice(
          this._tables.map((el) => el.id).indexOf(data.deletedTable),
          1
        );
      }
      if (data.table) {
        if (
          data.table.members.filter((el) => el && !!el.userID).length ===
          data.table.maxPlayers
        ) {
          data.table.status = TableStatusEnum.RUNNING;
        }
        this._tables[this._tables.map((el) => el.id).indexOf(data.table.id)] =
          data.table;
      }
    });
  }

  public async update(): Promise<void> {
    try {
      this._tables = await this.lobbyBackendService.getTables();
    } catch (e) {
      console.warn(e);
    }
  }

  public async updateOnlineList(): Promise<void> {
    try {
      this._playersOnline =
        await this.userBackendService.getCurrentlyOnlinePlayers();
      this.sortPlayersOnline();
    } catch (e) {
      console.warn(e);
    }
  }

  public get tables() {
    return this._tables;
  }

  public get playersOnline(): UserInfoInterface[] {
    return this._playersOnline;
  }

  public async joinAsSpectator(tableID: number): Promise<void> {
    try {
      await this.socketIO.post('game/watch', {
        tableID: tableID,
      });
    } catch (e) {
      console.warn('game/watch failed:', e);
    }
  }

  public async joinTable(tableID: number): Promise<void> {
    try {
      const table = await this.socketIO.post('game/table/join', {
        tableID: tableID,
      });
      await this.myPlayer.setMyTable(table);
    } catch (e) {
      if (e === 'userIsEnemyOfARoomMember')
        this.toastr.warning(
          'Du kannst dich nicht dazusetzen: ein Mitspieler ist blockiert.'
        );
      console.warn('game/table/join failed:', e);
    }
  }

  public async createTable(numPlayers: number = 2): Promise<void> {
    try {
      const table = await this.socketIO.post('game/table/create', {
        numPlayers: numPlayers,
      });
      await this.myPlayer.setMyTable(table);
    } catch (e) {
      this.toastr.warning('Tisch konnte nicht erstellt werden.');
      console.warn(e);
    }
  }

  public async leaveTable(): Promise<void> {
    try {
      await this.socketIO.post('game/table/leave');
    } catch (e) {}
    await this.myPlayer.setMyTable(undefined);
  }

  public getTablesList(): any[] {
    return this.tables.filter((el) => el.maxPlayers === this.filterNumPlayers);
  }

  public async moveToTrainingRoom(): Promise<void> {
    if (this.currentRoom === 'training') return;
    this.currentRoom = 'training';
    if (this.sitsOnTable()) await this.leaveTable();
  }

  public async moveToStandardRoom(): Promise<void> {
    if (this.currentRoom === 'standard') return;
    this.currentRoom = 'standard';
    if (this.sitsOnTable()) await this.leaveTable();
  }

  public sitsOnTable(): boolean {
    return this.myPlayer.player && !!this.myPlayer.player.tableID;
  }

  public async changeFilterNumPlayers(v: 2 | 3 | 4): Promise<void> {
    this.filterNumPlayers = v;
  }

  private sortPlayersOnline(): void {
    this._playersOnline.sort(
      (
        a,
        b // sort alphabetically
      ) =>
        a.username.localeCompare(b.username, undefined, {
          sensitivity: 'base',
        })
    );
    this._playersOnline.sort((a, b) => {
      // sort by isEnemy
      if (a.isEnemy && !b.isEnemy) {
        return -1;
      } // a davor
      if (!a.isEnemy && b.isEnemy) {
        return 1;
      } // a danach
      return 0; // gleich
    });
    this._playersOnline.sort((a, b) => {
      // sort by isFriend
      if (a.isFriend && !b.isFriend) {
        return -1;
      } // a davor
      if (!a.isFriend && b.isFriend) {
        return 1;
      } // a danach
      return 0; // gleich
    });
    this._playersOnline.sort((a, b) => {
      // sort by isInGame
      if (a.isInGame && !b.isInGame) {
        return 1;
      } // a danach
      if (!a.isInGame && b.isInGame) {
        return -1;
      } // a davor
      return 0; // gleich
    });
    const myListEntry = this._playersOnline.find(
      (el) => el.userID === this.myPlayer.player.userID
    ); // filter out my entry
    this._playersOnline = this._playersOnline.filter(
      (el) => el.userID !== this.myPlayer.player.userID
    ); // filter out my entry
    if (myListEntry) this._playersOnline.unshift(myListEntry); // add my entry to beginning
  }
}
