import { AnnouncementType } from '../../types';
import { IBuilder, Builder } from 'builder-pattern';

export interface RoundAnnouncementInterface {
  playerIdx: number;
  announcement: AnnouncementType;
}

export namespace RoundAnnouncementInterface {
  export function builder(
    template?: Partial<RoundAnnouncementInterface>
  ): IBuilder<RoundAnnouncementInterface> {
    return Builder<RoundAnnouncementInterface>(template);
  }
}
